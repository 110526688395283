import React, { useState } from "react";
import { Outlet } from "react-router-dom";

const ProjectContext = React.createContext(null);

export const useProjectContext = () => {
  return React.useContext(ProjectContext);
};

function ProjectDataProvider() {
  const [refreshFlag, setRefreshFlag] = useState(true);

  return (
    <ProjectContext.Provider 
    value={{ 
      RefreshFlag: refreshFlag,
      SetRefreshFlag: setRefreshFlag,
    }}>
      <Outlet />
    </ProjectContext.Provider>
  );
}

export default ProjectDataProvider;
