import React, { useEffect,useState } from "react";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom/dist";
import { useAuthContext } from "./AuthDataProvider";

const MainContext = React.createContext(null);

export const useMainContext = () => {
  return React.useContext(MainContext);
};

function MainDataProvider({ children }) {
  const authContext = useAuthContext();
  const navigate = useNavigate();
  const [showAddUser, setShowAddUser] = useState(false);
  const [showProjectDetail, setShowProjectDetail] = useState(false);
  const [projectMode, setProjectMode] = useState('');
  const [projectId, setProjectId] = useState('');

    useEffect(() => {
    if (authContext.AuthData === null) {
      navigate("/login");
    }
    return () => {};
  }, []);

  const handleAddUser = (status) => {
    //console.log("show dialog");
    setShowAddUser(status)
  };

  const handleAddProject = (status) => {
    //console.log("show dialog");
    setShowProjectDetail(status)
  };

  return (
    <MainContext.Provider 
    value={{ 
      HandleAddUser: handleAddUser,
      ShowAddUser: showAddUser,
      HandleAddProject: handleAddProject,
      ShowProjectDetail: showProjectDetail,
      ProjectMode: projectMode,
      SetProjectMode: setProjectMode,
      ProjectId: projectId,
      SetProjectId: setProjectId,
    }}>
      <Outlet />
    </MainContext.Provider>
  );
}

export default MainDataProvider;
