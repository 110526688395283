import React, { useEffect, useState, useRef } from 'react';
import "./MessageBox.scss";

const MessageBox = ({id, type, message, onClose }) => {
    const [fadeOut, setFadeOut] = useState(false);
    const timerRef = useRef(null);

    useEffect(() => {
        timerRef.current = setTimeout(() => {
            setFadeOut(true);
        }, 5000);

        return () => {
            clearTimeout(timerRef.current);
        };
    }, []);

    useEffect(() => {
        if (fadeOut) {
            setTimeout(() => {
                onClose(id);
            }, 500);
        }
    }, [fadeOut, id, onClose]);

    const errorContainerStyles = {
        opacity: fadeOut ? 0 : 1,
        transition: 'opacity 0.5s ease-out',
    };

    const handleOnClose = () => {
        clearTimeout(timerRef.current);
        onClose(id);
    };

    return (
        <div style={errorContainerStyles} className="error-container-item">
            {type === "Error" && <div className="error-line bg-red"></div>}
            {type === "Success" && <div className="error-line bg-lightgreen"></div>}
            {type === "Warning" && <div className="error-line bg-yellow"></div>}
            {type === "Information" && <div className="error-line bg-darkblue"></div>}
            {type === "Detection" && <div className="error-line bg-lightorange"></div>}
            <div className="error-icon ms-2 me-2">
                {type === "Error" && <i className="fa-solid fa-circle-xmark col-red custom-icon"></i>}
                {type === "Success" && <i className="fa-solid fa-circle-check col-lightgreen custom-icon"></i>}
                {type === "Warning" && <i className="fa-solid fa-circle-exclamation col-yellow "></i>}
                {type === "Information" && <i className="fa-solid fa-circle-question col-darkblue custom-icon"></i>}
                {type === "Detection" && <i className="fa-solid fa-whale col-lightorange custom-icon"></i>}
            </div>
            <div>
                <div className="error-title">{type}</div>
                <div className="error-description me-2">{message}</div>
            </div>
            <button className="close-button" onClick={handleOnClose}>
                <i className="fa-solid fa-xmark custom-close-icon fa-lg col-grey"></i>
            </button>
        </div>
    );
};

export default MessageBox;
