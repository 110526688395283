import React, { useEffect, useRef, useState } from "react";
import { useAuthContext } from "../../dataproviders/AuthDataProvider";
import { NavLink } from "react-router-dom/dist";

import "./LoginForm.scss";

function LoginForm() {
  const authContext = useAuthContext();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const [isLoginError, setIsLoginError] = useState(false);

  useEffect(() => {
    if (authContext.ErrorMessage.value === "") {
      return;
    }

    authContext.ErrorMessage.setValue("");

    return () => {};
  }, [username, password]);

  const handleUsernameOnBlur = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(username)) {
      setIsEmailInvalid(true);
    } else {
      setIsEmailInvalid(false);
    }
  };

    const handleOnSubmit = async (event) => {
        event.preventDefault();
        const btn = document.getElementById("btnLogin");
      try {
          // Disable the button and add the loading class
          btn.disabled = true;
          btn.innerHTML = '';
          btn.classList.add('loading');

          if (username === "" || password === "" || isEmailInvalid) {
              setIsLoginError(true);
          }

          await authContext.Login(username, password);
      }
      catch (error) {
          // Display error message for network errors
          //handleError('Error', 'An error occurred. Please try again later.');
      }
      finally {
          // Enable the button and remove the loading class
          btn.disabled = false;
          btn.innerHTML = 'Sign In';
          btn.classList.remove('loading');
      }
  };

  const hasUsernameError = () => {
    if ((isLoginError && !username) || (username && isEmailInvalid)) {
      return "error";
    } else {
      return "";
    }
  };

  const hasPasswordError = () => {
    if (isLoginError && !password) {
      return "error";
    } else {
      return "";
    }
  };

  const renderUsernameErrorMessage = () => {
    if (isLoginError && !username) {
      return (
        <div>
          <label className="offset-w-30 error">
            <i className="fa-solid fa-circle-info"></i> Email is required.
          </label>
        </div>
      );
    }

    if (!!username && isEmailInvalid) {
      return (
        <label className="offset-w-30 error">
            <i className="fa-solid fa-circle-info"></i> Email format is invalid.
        </label>
      );
    }
  };

  const renderPasswordErrorMessage = () => {
    if (isLoginError && !password) {
      return (
        <>
          <label className="offset-w-30 error">
            <i className="fa-solid fa-circle-info"></i> Password is required.
          </label>
        </>
      );
    }
  };

  return (
    <>
      <div className="login-container-title">
        <div className="login-container-title-logo"></div>
        <div className="login-container-title-text mb-3 mt-1">
          Sign in with email address
        </div>
      </div>
      <div className="login-container-error">
        {authContext.ErrorMessage.value}
          </div>
          <form className="login-container-form" onSubmit={handleOnSubmit} method="POST">
        <div className="mb-2">
          <div className="d-flex align-items-center input-label-group">
            <label htmlFor="username" className="w-30 required">
              Email Address
            </label>
            <input
              type="text"
              name="username"
              id="username"
              className={`${hasUsernameError()} w-70`}
              onBlur={handleUsernameOnBlur}
              onChange={(e) => {
                setUsername(e.target.value);
              }}
            />
          </div>
          {renderUsernameErrorMessage()}
        </div>
        <div className="mb-1">
          <div className="d-flex align-items-center input-label-group">
            <label htmlFor="password" className="w-30 required">
              Password
            </label>
            <input
              type="password"
              name="password"
              id="password"
              className={`${hasPasswordError()} w-70`}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </div>
          {renderPasswordErrorMessage()}
        </div>
        <div className="mb-3 d-flex">
          <div className="w-30"></div>
          <NavLink to="/login/resetpassword" className="link reset-password-link">Reset your password</NavLink>
        </div>
        <div className="pt-2">
          <button id="btnLogin" className="btn btn-primary form-control" type="submit">Sign In</button>
        </div>
      </form>
    </>
  );
}

export default LoginForm;
