import React from "react";
import { useNavigate } from "react-router-dom/dist";

function ResetPassword() {
  const navigate = useNavigate();

  return (
    <>
      <div className="login-container-title">
        <div className="login-container-title-logo"></div>
        <div className="login-container-title-text reset-password">
          Reset your password
        </div>
      </div>
      <div className="login-container-reset-password">
        <div className="content">
          Please contact the System Administrator to reset your password.
        </div>
      </div>
      <button
        type="button"
        className="btn btn-primary form-control"
        onClick={() => {
          navigate("/login");
        }}
      >
        Back to Sign In
      </button>
    </>
  );
}

export default ResetPassword;
