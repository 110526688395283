import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import UserServices from "../services/api/UserServices";
import { useNavigate } from "react-router-dom/dist";

const AuthContext = React.createContext(null);

export const useAuthContext = () => {
  return React.useContext(AuthContext);
};

function AuthDataProvider({ children }) {
  const [errorMessage, setErrorMessage] = useState("");
  const [auth, setAuth] = useState(JSON.parse(sessionStorage.getItem("auth")));
  const navigate = useNavigate();
  const [errors, setErrors] = useState([]);

    useEffect(() => {
        const _auth = JSON.parse(sessionStorage.getItem("auth"));
        if (!!_auth) {
            setAuth(_auth);
            navigate("/projects");
        }

        return () => {};
    }, []);

  const login = async (username, password) => {
    // console.log("login");
    const authResult = await UserServices.Login(username, password);

      if (!!authResult) {
          const auth = authResult.data;

          if (auth.success) {
              sessionStorage.setItem("auth", JSON.stringify(auth));
              setAuth(auth);

              navigate("/projects");
          } else {
              setErrorMessage(auth.message);
          }
      }
      else {
          handleError('Error', 'An error occurred. Please try again later.');
      }
  };

  const logout = () => {
    //console.log("logging out");
    setAuth(null);
    setErrorMessage("");
    sessionStorage.removeItem("auth");

    navigate("/login");
  };

    const handleError = (errorType, errorMessage) => {
        setErrors((prevErrors) => {
            // Remove the oldest error if the maximum limit is reached
            if (prevErrors.length >= 10) {
                prevErrors.shift();
            }

            const newError = {
                id: Date.now(),
                errorType,
                errorMessage
            };
            return [...prevErrors, newError];
        });
    }

  return (
    <AuthContext.Provider
      value={{
        Login: login,
        Logout: logout,
        ErrorMessage: {
          value: errorMessage,
          setValue: setErrorMessage,
        },
        AuthData: auth,
        HandleError: handleError,
        Errors: errors,
        SetErrors: setErrors,
      }}
    >
      <Outlet />
    </AuthContext.Provider>
  );
}

export default AuthDataProvider;
