import axios from "axios";
import { baseUrl } from "../constants";
//import { setBearerAuthorizationHeader } from "../../utils/interceptors";

const Login = (username, password) => {
  return axios
    .post(`${baseUrl()}/User/Login`, {
      username,
      password,
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.error("UserServices: Login API", error);
    });
};

const GetUsers = () => {
    return axios
        .get(`${baseUrl()}/User/All`)
        .then((data) => {
            return data;
        })
        .catch((error) => {
            if (error.response.status === 307) {
                // Handle the specific status code 307 - Status307TemporaryRedirect
                const errResponse = error.response.data;
                let newToken = errResponse.token;

                const _auth = JSON.parse(sessionStorage.getItem("auth"));
                _auth.token = newToken;
                sessionStorage.setItem("auth", JSON.stringify(_auth));

                return axios
                    .get(`${baseUrl()}/User/All`)
                    .then((data) => {
                        return data;
                    })
                    .catch((error) => {
                        console.error("UserServices: Get Users API", error);
                    });
            } else {
                console.error("UserServices: Get Users API", error);
            }
        });
};

const SetUserStatus = (username, status) => {
    return axios
        .post(`${baseUrl()}/User/SetUserStatus`, {
            username,
            status
        })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            if (error.response.status === 307) {
                // Handle the specific status code 307 - Status307TemporaryRedirect
                const errResponse = error.response.data;
                let newToken = errResponse.token;

                const _auth = JSON.parse(sessionStorage.getItem("auth"));
                _auth.token = newToken;
                sessionStorage.setItem("auth", JSON.stringify(_auth));

                return axios
                    .post(`${baseUrl()}/User/SetUserStatus`, {
                        username,
                        status
                    })
                    .then((data) => {
                        return data;
                    })
                    .catch((error) => {
                        console.error("UserServices: Set User Status API", error);
                    });
            } else {
                console.error("UserServices: Set User Status API", error);
            }
        });
};

const ResetPassword = (username, currentpassword, newpassword) => {
    return axios
        .post(`${baseUrl()}/User/ResetPassword`, {
            username,
            currentpassword,
            newpassword
        })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            if (error.response.status === 307) {
                // Handle the specific status code 307 - Status307TemporaryRedirect
                const errResponse = error.response.data;
                let newToken = errResponse.token;

                const _auth = JSON.parse(sessionStorage.getItem("auth"));
                _auth.token = newToken;
                sessionStorage.setItem("auth", JSON.stringify(_auth));

                return axios
                    .post(`${baseUrl()}/User/ResetPassword`, {
                        username,
                        currentpassword,
                        newpassword
                    })
                    .then((data) => {
                        return data;
                    })
                    .catch((error) => {
                        console.error("UserServices: Reset Password API", error);
                    });
            } else {
                console.error("UserServices: Reset Password API", error);
            }
        });
};


const AddUser = (username,password, displayname,role) => {
    return axios
        .post(`${baseUrl()}/User/AddUser`, {
            username,
            password,
            displayname,
            role,
        })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            if (error.response.status === 307) {
                // Handle the specific status code 307 - Status307TemporaryRedirect
                const errResponse = error.response.data;
                let newToken = errResponse.token;

                const _auth = JSON.parse(sessionStorage.getItem("auth"));
                _auth.token = newToken;
                sessionStorage.setItem("auth", JSON.stringify(_auth));

                return axios
                    .post(`${baseUrl()}/User/AddUser`, {
                        username,
                        password,
                        displayname,
                        role,
                    })
                    .then((data) => {
                        return data;
                    })
                    .catch((error) => {
                        console.error("UserServices: Add User Status API", error);
                    });
            } else {
                console.error("UserServices: Add User Status API", error);
            }
        });
};

const GetPamOperators = () => {
    return axios
      .get(`${baseUrl()}/User/GetPamOperators`)
      .then((data) => {
        return data;
      })
        .catch((error) => {
            if (error.response.status === 307) {
                // Handle the specific status code 307 - Status307TemporaryRedirect
                const errResponse = error.response.data;
                let newToken = errResponse.token;

                const _auth = JSON.parse(sessionStorage.getItem("auth"));
                _auth.token = newToken;
                sessionStorage.setItem("auth", JSON.stringify(_auth));

                return axios
                    .get(`${baseUrl()}/User/GetPamOperators`)
                    .then((data) => {
                        return data;
                    })
                    .catch((error) => {
                        console.error("UserServices: Get Pam Operator API", error);
                    });
            } else {
                console.error("UserServices: Get Pam Operator API", error);
            }
      });
  };

const Logout = () => {
return axios
    .post(`${baseUrl()}/User/Logout`)
    .then((data) => {
    return data;
    })
    .catch((error) => {
  
    if (error.response.status === 307) {
        // Handle the specific status code 307 - Status307TemporaryRedirect
        const errResponse = error.response.data;
        let newToken = errResponse.token;

        const _auth = JSON.parse(sessionStorage.getItem("auth"));
        _auth.token = newToken;
        sessionStorage.setItem("auth", JSON.stringify(_auth));

        return axios
            .get(`${baseUrl()}/User/Logout`)
            .then((data) => {
                return data;
            })
            .catch((error) => {
                console.error("UserServices: Logout API", error);
            });
    } else {
        console.error("UserServices: Logout API", error);
    }
    });
};

const UserServices = { Login, GetUsers, SetUserStatus, AddUser, ResetPassword, GetPamOperators, Logout };

export default UserServices;
