import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import { useNavigate } from "react-router-dom/dist";

import "./UserManagement.scss";
import "./NewUser";
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import UserServices from "../../services/api/UserServices";
import NewUser from './NewUser';
import { useMainContext } from "../../dataproviders/MainDataProvider";
import { useUserContext } from "../../dataproviders/UserDataProvider";
import { useAuthContext } from '../../dataproviders/AuthDataProvider';

function UserManagement() {
    const mainContext = useMainContext();
    const authContext = useAuthContext();
    const userContext = useUserContext();
    const navigate = useNavigate();
    const gridRef = useRef(); // Optional - for accessing Grid's API
    const [rowData, setRowData] = useState(); // Set rowData to Array of Objects, one Object per Row
    const [containerHeight, setContainerHeight] = useState(0); // Initialize containerHeight
    const [isMobileScreen, setIsMobileScreen] = useState(false); // Initialize the mobile screen state
    const [errors, setErrors] = useState([]);
    const [password, setPassword] = useState("default");
    const [invalidPasswordError, setInvalidPasswordError] = useState(false);
    
    class UserManagementCellRenderer {
        init(params) {
            this.params = params;
            this.eGui = this.createContextMenu();
        }

        createContextMenu() {
            const contextMenu = document.createElement('div');
            contextMenu.classList.add('context-menu');
            contextMenu.classList.add('dropdown');
            contextMenu.classList.add('dropdown-usermgmt');

            const toggleBtn = document.createElement('button');
            toggleBtn.classList.add('dropdown-toggle');
            toggleBtn.setAttribute('type', 'button');
            toggleBtn.innerHTML = '<i class="fa-solid fa-ellipsis-vertical"></i>';
            toggleBtn.addEventListener('click', this.toggleHandler);

            const dropdownMenu = document.createElement('div');
            dropdownMenu.classList.add('dropdown-menu');

            // reset password
            const resetPasswordBtn = document.createElement('button');
            resetPasswordBtn.classList.add('dropdown-item');
            resetPasswordBtn.innerHTML = '<i class="fa-solid fa-lock-hashtag"></i> Reset Password';
            this.resetPasswordHandler = this.resetPasswordHandler.bind(this);
            resetPasswordBtn.addEventListener('click', this.resetPasswordHandler);

            // activate/deactivate user
            const toggleStatusBtn = document.createElement('button');
            toggleStatusBtn.classList.add('dropdown-item');

            if (this.params.data.Status === 1) {
                toggleStatusBtn.innerHTML = '<i class="fa-solid fa-user-large-slash"></i> Deactivate User';
                this.deactivateStatusHandler = this.deactivateStatusHandler.bind(this);
                toggleStatusBtn.addEventListener('click', this.deactivateStatusHandler);
            }
            else {
                toggleStatusBtn.innerHTML = '<i class="fa-solid fa-user-check"></i> Activate User';
                this.activateStatusHandler = this.activateStatusHandler.bind(this);
                toggleStatusBtn.addEventListener('click', this.activateStatusHandler);
            }

            // add buttons to dropdown menu
            dropdownMenu.appendChild(resetPasswordBtn);
            dropdownMenu.appendChild(toggleStatusBtn);

            contextMenu.appendChild(toggleBtn);
            contextMenu.appendChild(dropdownMenu);
            return contextMenu;
        }

        getGui() {
            return this.eGui;
        }

        toggleHandler(e) {
            let el = e.target.closest('.dropdown-usermgmt').querySelector('.dropdown-menu');

            // Check if the element has the .show class
            if (el.classList.contains('show')) {
                //hide all dropdown-menu with .show
                const dropdownMenus = document.querySelectorAll('.dropdown-menu.show');
                dropdownMenus.forEach((dropdownMenu) => {
                    dropdownMenu.classList.remove('show');
                });

                //hide all dropdown-menu with .show
                const dropdownMenusMgmt = document.querySelectorAll('.dropdown-usermgmt.col-rps-light');
                dropdownMenusMgmt.forEach((dropdownMenu) => {
                    dropdownMenu.classList.remove('col-rps-light');
                });
            }
            else {
                //hide all dropdown-menu with .show
                const dropdownMenus = document.querySelectorAll('.dropdown-menu.show');
                dropdownMenus.forEach((dropdownMenu) => {
                    dropdownMenu.classList.remove('show');
                });

                //hide all dropdown-menu with .show
                const dropdownMenusMgmt = document.querySelectorAll('.dropdown-usermgmt.col-rps-light');
                dropdownMenusMgmt.forEach((dropdownMenu) => {
                    dropdownMenu.classList.remove('col-rps-light');
                });

                const dropdownMenu = el;
                dropdownMenu.classList.toggle('show');

                e.target.closest('.dropdown-usermgmt').classList.toggle('col-rps-light');
            }

            const container = document.querySelector('.user-management-container');
            const targetElement = container.querySelector('.dropdown-menu.show');

            if (targetElement) {
                const containerScrollHeight = container.scrollHeight;
                const containerRect = container.getBoundingClientRect();
                const targetElementRect = targetElement.getBoundingClientRect();

                const targetTop = targetElementRect.top - containerRect.top + container.scrollTop;
                const targetHeight = targetElementRect.height;

                if (targetTop + targetHeight > containerScrollHeight) {
                    targetElement.style.top = (-targetHeight) + 'px';
                }
            }

        }

        resetPasswordHandler(e) {
            const container = document.querySelector('.dialog-overlay-reset-password');

            if (container) {
                //hide all dropdown-menu with .show
                const dropdownMenus = document.querySelectorAll('.dropdown-menu.show');
                dropdownMenus.forEach((dropdownMenu) => {
                    dropdownMenu.classList.remove('show');
                });

                //hide all dropdown-menu with .show
                const dropdownMenusMgmt = document.querySelectorAll('.dropdown-usermgmt.col-rps-light');
                dropdownMenusMgmt.forEach((dropdownMenu) => {
                    dropdownMenu.classList.remove('col-rps-light');
                });

                container.classList.remove('hide');

                const btn = document.querySelector('.btn-user-reset-password');
                btn.setAttribute('userName', this.params.data.Username);
            }
        }

        activateStatusHandler(e) {
            const container = document.querySelector('.dialog-overlay-activate');

            if (container) {
                //hide all dropdown-menu with .show
                const dropdownMenus = document.querySelectorAll('.dropdown-menu.show');
                dropdownMenus.forEach((dropdownMenu) => {
                    dropdownMenu.classList.remove('show');
                });

                //hide all dropdown-menu with .show
                const dropdownMenusMgmt = document.querySelectorAll('.dropdown-usermgmt.col-rps-light');
                dropdownMenusMgmt.forEach((dropdownMenu) => {
                    dropdownMenu.classList.remove('col-rps-light');
                });

                container.classList.remove('hide');

                const preferedNames = document.querySelectorAll('.prefered-name-section');
                preferedNames.forEach((preferedName) => {
                    preferedName.innerHTML = this.params.data.PreferedName;
                });

                const btn = document.querySelector('.btn-user-activate');
                btn.setAttribute('userName', this.params.data.Username);
                btn.setAttribute('preferedName', this.params.data.PreferedName);
            }
        }

        deactivateStatusHandler(e) {
            const container = document.querySelector('.dialog-overlay-deactivate');

            if (container) {
                //hide all dropdown-menu with .show
                const dropdownMenus = document.querySelectorAll('.dropdown-menu.show');
                dropdownMenus.forEach((dropdownMenu) => {
                    dropdownMenu.classList.remove('show');
                });

                //hide all dropdown-menu with .show
                const dropdownMenusMgmt = document.querySelectorAll('.dropdown-usermgmt.col-rps-light');
                dropdownMenusMgmt.forEach((dropdownMenu) => {
                    dropdownMenu.classList.remove('col-rps-light');
                });
                container.classList.remove('hide');

                const preferedNames = document.querySelectorAll('.prefered-name-section');
                preferedNames.forEach((preferedName) => {
                    preferedName.innerHTML = this.params.data.PreferedName;
                });

                const btn = document.querySelector('.btn-user-deactivate');
                btn.setAttribute('userName', this.params.data.Username);
                btn.setAttribute('preferedName', this.params.data.PreferedName);
            }
        }

        destroy() {
            this.eGui.removeEventListener('click', this.toggleHandler);
            this.eGui.removeEventListener('click', this.resetPasswordHandler);
            this.eGui.removeEventListener('click', this.activateStatusHandler);
            this.eGui.removeEventListener('click', this.deactivateStatusHandler);
        }
    }

    // Each Column Definition results in one Column.
    const [columnDefs, setColumnDefs] = useState([
        {
            headerName: '',
            field: 'Username',
            cellClass: ['actions-button-cell'],
            cellRenderer: UserManagementCellRenderer,
            minWidth: 24,
            maxWidth: 24,
            sortable: false,
            filter: false,
            resizable: false
        },
        {
            headerName: 'Username',
            cellClass: ['d-flex', 'text-wrap', 'ag-cell-custom-text'],
            field: 'Username'
        },
        {
            headerName: 'Display Name',
            cellClass: ['d-flex', 'text-wrap', 'ag-cell-custom-text'],
            field: 'PreferedName'
        },
        {
            headerName: 'Role',
            field: 'Role',
            cellClass: ['d-flex', 'text-wrap', 'ag-cell-custom-text'],
            maxWidth: 180,
            minWidth: 180,
            cellRenderer: params => {
                var cell = '';
                if (params.data.Role === 0) {
                    cell += 'System Administrator';
                }
                else if (params.data.Role === 1) {
                    cell += 'PAM Operator';
                }
                else if (params.data.Role === 2) {
                    cell += 'Project Manager';
                }
                else if (params.data.Role === 3) {
                    cell += 'Client';
                }
                else {
                    cell += 'Others';
                }

                return cell;
            },
            resizable: false
        },
        {
            headerName: 'Status',
            field: 'Status',
            maxWidth: 120,
            minWidth: 120,
            cellRenderer: params => {
                var cell = '';
                if (params.data.Status === 1) {
                    cell += 'Active';
                }
                else {
                    cell += 'Deactivated';
                }

                return cell;
            },
            cellClass: params => {
                if (params.data.Status === 1) {
                    return ['d-flex', 'text-wrap', 'col-green', 'ag-cell-custom-text'];
                }
                else {
                    return ['d-flex', 'text-wrap', 'ag-cell-custom-text'];
                }
            },
            resizable: false
        }
    ]);

    const sizeToFit = useCallback(() => {
        // Check if gridRef.current is not null before accessing the 'api' property
        if (gridRef.current) {
            gridRef.current.api.sizeColumnsToFit({
                defaultMinWidth: 100
            });
        }
    }, []);

    const handleResize = useCallback(() => {
        // Calculate the new container height on window resize
        const newContainerHeight = window.innerHeight - 350;
        setContainerHeight(newContainerHeight);

        // Detect mobile screen size (width <= 768px)
        const isMobile = window.innerWidth <= 768;
        setIsMobileScreen(isMobile);

        sizeToFit();
    }, []);

    // DefaultColDef sets props common to all Columns
    const defaultColDef = useMemo(() => ({
        sortable: true,
        filter: true,
        resizable: true,
        suppressMovable: true,
        // override all the defaults with font awesome
        icons: {
            // use font awesome for menu icons
            menu:'<i class="fa-solid fa-filter"></i>'
        }
    }));

    // Example of consuming Grid Event
    const cellClickedListener = useCallback(e => {
        const headerName = e.colDef.headerName;
        const colIndex = e.columnApi
            .getAllColumns()
            ?.findIndex((col) => col.getColDef().headerName === headerName);

        //if not first cell, go inside to hide all
        if (colIndex > 0) {
            //hide all dropdown-menu with .show
            const dropdownMenus = document.querySelectorAll('.dropdown-menu.show');
            dropdownMenus.forEach((dropdownMenu) => {
                dropdownMenu.classList.remove('show');
            });

            //hide all dropdown-menu with .show
            const dropdownMenusMgmt = document.querySelectorAll('.dropdown-usermgmt.col-rps-light');
            dropdownMenusMgmt.forEach((dropdownMenu) => {
                dropdownMenu.classList.remove('col-rps-light');
            });
        }

    }, []);

    useEffect(() => {
        // Add event listener for screen size change
        window.addEventListener('resize', handleResize);
        return () => {
            // Cleanup: remove event listener on component unmount
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (rowData !== undefined) {
            handleResize();
        }
    }, [rowData]); // Add rowData as a dependency so that this useEffect only runs when rowData changes

    // Example load data from server
    useEffect(() => {
        const _auth = JSON.parse(sessionStorage.getItem("auth"));
      
        if (!!_auth) {
            const role = _auth.role;

            //0:Admin, 1:Operator, 2:Project Manager, 3:Client
            if (role === 0 || role === 2) {
                getUsers();
            }
            else {
                navigate("/projects");
            }
        }
        else {
            getUsers();
        }

        userContext.SetRefreshFlag(false);
    }, [userContext.RefreshFlag]);

    const getUsers = async () => {
        const result = await UserServices.GetUsers();

        if (!!result) {
            setRowData(result.data);
        }
    };

    const handleResetPassword = async (e) => {
        let password = document.getElementById("txtAdminNewPassword").value;
        setPassword(password);

        if (!(password === '' || invalidPasswordError === true)) {
            const cancelButton = e.currentTarget;

            try {
                // Disable the button and add the loading class
                cancelButton.disabled = true;
                cancelButton.innerHTML = '';
                cancelButton.classList.add('loading');

                let username = cancelButton.getAttribute('userName');

                const flag = await UserServices.ResetPassword(username, '', password);

                if (!!flag) {
                    const result = await UserServices.GetUsers();

                    if (!!result) {
                        setRowData(result.data);
                    }

                    if (flag.data !== null &&
                        flag.data.errormessage === null) {
                        handleError('Success', 'Password has been successfully reset.');
                    }
                    else {
                        handleError('Error', 'Password has failed to reset. Please try again.');
                    }
                }
                else {
                    // Display error message for network errors
                    handleError('Error', 'An error occurred. Please try again later.');
                }

                hideResetPassword(cancelButton);
            }
            catch (error) {
                // Display error message for network errors
                handleError('Error', 'An error occurred. Please try again later.');
            }
            finally {
                // Enable the button and remove the loading class
                cancelButton.disabled = false;
                cancelButton.innerHTML = 'Yes';
                cancelButton.classList.remove('loading');
            }
        }
    };

    const handleDeactivate = async (e) => {
        const cancelButton = e.currentTarget;

        try {
            // Disable the button and add the loading class
            cancelButton.disabled = true;
            cancelButton.innerHTML = '';
            cancelButton.classList.add('loading');

            let username = cancelButton.getAttribute('userName');

            const flag = await UserServices.SetUserStatus(username, 0);

            if (!!flag) {
                const result = await UserServices.GetUsers();

                if (!!result) {
                    setRowData(result.data);
                }

                let preferedName = cancelButton.getAttribute('preferedName');
                handleError('Success', preferedName + "'s account has been deactivated.");
            }
            else {
                // Display error message for network errors
                handleError('Error', 'An error occurred. Please try again later.');
            }

            hideDeactivate(cancelButton);
        }
        catch (error) {
            // Display error message for network errors
            handleError('Error', 'An error occurred. Please try again later.');
        }
        finally {
            // Enable the button and remove the loading class
            cancelButton.disabled = false;
            cancelButton.innerHTML = 'Yes';
            cancelButton.classList.remove('loading');
        }
    };

    const handleActivate = async (e) => {
        const cancelButton = e.currentTarget;

        try {
            // Disable the button and add the loading class
            cancelButton.disabled = true;
            cancelButton.innerHTML = '';
            cancelButton.classList.add('loading');

            let username = cancelButton.getAttribute('userName');

            const flag = await UserServices.SetUserStatus(username, 1);

            if (!!flag) {
                const result = await UserServices.GetUsers();

                if (!!result) {
                    setRowData(result.data);
                }

                let preferedName = cancelButton.getAttribute('preferedName');
                handleError('Success', preferedName + "'s account has been activated.");
            }
            else {
                // Display error message for network errors
                handleError('Error', 'An error occurred. Please try again later.');
            }

            hideActivate(cancelButton);
        }
        catch (error) {
            // Display error message for network errors
            handleError('Error', 'An error occurred. Please try again later.');
        }
        finally {
            // Enable the button and remove the loading class
            cancelButton.disabled = false;
            cancelButton.innerHTML = 'Yes';
            cancelButton.classList.remove('loading');
        }
    };

    const handleCancelResetPassword = (e) => {
        const cancelButton = e.currentTarget;
        hideResetPassword(cancelButton);
    };

    const handleCancelActivate = (e) => {
        const cancelButton = e.currentTarget;
        hideActivate(cancelButton);
    };

    const handleCancelDeactivate = (e) => {
        const cancelButton = e.currentTarget;
        hideDeactivate(cancelButton);
    };

    const handleError = (errorType, errorMessage) => {
        authContext.HandleError(errorType,errorMessage);
    }

    const hideResetPassword = (btn) => {
        document.getElementById("txtAdminNewPassword").value = '';
        setPassword('default');
        setInvalidPasswordError(false);

        const dialogOverlay = btn.closest('.dialog-overlay-reset-password');

        if (dialogOverlay) {
            dialogOverlay.classList.add('hide');
        }
    };

    const hideActivate = (btn) => {
        const dialogOverlay = btn.closest('.dialog-overlay-activate');

        if (dialogOverlay) {
            dialogOverlay.classList.add('hide');
        }
    };

    const hideDeactivate = (btn) => {
        const dialogOverlay = btn.closest('.dialog-overlay-deactivate');

        if (dialogOverlay) {
            dialogOverlay.classList.add('hide');
        }
    };

    const hasPasswordError = () => {
        if (password !== 'default' &&
            (password === '' || invalidPasswordError === true)) {
            return "error";
        } else {
            return "";
        }
    };

    const renderPasswordErrorMessage = () => {
        if (password === '') {
            return (
                <>
                    <label className="offset-w-25 error">
                        <i className="fa-solid fa-circle-info"></i> Password is required.
                    </label>
                </>
            );
        }
        else if (invalidPasswordError === true) {
            return (
                <>
                    <label className="offset-w-25 error">
                        <i className="fa-solid fa-circle-info"></i> Password must include at least 3 special characters e.g. ~!@#$%^&*()
                    </label>
                </>
            );
        }
    };

    const validatePassword = (e) => {
        setPassword(e);

        if (password !== '') {
            const passwordReset = /^(.*[~!@#$%^&*()]){3,}.*$/;

            if (!passwordReset.test(e)) {
                setInvalidPasswordError(true);
            } else {
                setInvalidPasswordError(false);
            }
        }
    };

    return (
        <>
       
            <div className="user-management-container" style={{ minHeight: '295px', height: `${!isMobileScreen ? `${containerHeight}px` : '100vh'}` }}>
                {/* On div wrapping Grid a) specify theme CSS Class Class and b) sets Grid size */}
                <div className="ag-theme-alpine user-management-with-context-menu" style={{ width: '100%', height: '100%' }}>

                    <AgGridReact
                        ref={gridRef} // Ref for accessing Grid's API

                        rowData={rowData} // Row Data for Rows
                        headerHeight={30}
                        rowHeight={30}
                        columnDefs={columnDefs} // Column Defs for Columns
                        defaultColDef={defaultColDef} // Default Column Properties

                        animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                        rowSelection='single' // Options - allows click selection of rows
                        suppressMenuHide={true}
                        // Your grid options here....
                        suppressCellSelection={true}
                        onCellClicked={cellClickedListener} // Optional - registering for Grid Event
                    />
                </div>
                <div className="dialog-overlay dialog-overlay-reset-password hide">
                    <div className="dialog-container dialog-container-long">
                        <button className="dialog-close-button" onClick={handleCancelResetPassword}>
                            <i className="fa-solid fa-xmark"></i>
                        </button>
                        <p className="dialog-title col-rps-light mt-0 mb-0">Reset Password</p>
                        <div className="dialog-content">
                            <div className="mb-2">
                                <div className="d-flex align-items-center input-label-group">
                                    <label htmlFor="txtAdminNewPassword" className="w-25 required">New Password</label>
                                    <input type="text" id="txtAdminNewPassword" className={`${hasPasswordError()} w-75`} onChange={(e) => { validatePassword(e.target.value); }} />
                                </div>
                                {renderPasswordErrorMessage()}
                            </div>
                        </div>
                        <div className="dialog-buttons float-right">
                            <button className="btn btn-dialog btn-primary btn-me btn-user-reset-password" onClick={handleResetPassword}>Yes</button>
                            <button className="btn btn-dialog btn-secondary" onClick={handleCancelResetPassword}>No</button>
                        </div>
                    </div>
                </div>
                <div className="dialog-overlay dialog-overlay-activate hide">
                    <div className="dialog-container">
                        <button className="dialog-close-button" onClick={handleCancelActivate}>
                            <i className="fa-solid fa-xmark"></i>
                        </button>
                        <p className="dialog-title col-rps-light mt-0 mb-0">Activate Account</p>
                        <p className="dialog-content">Are you sure wish to activate <span className="prefered-name-section"></span>'s account?</p>
                        <div className="dialog-buttons float-right">
                            <button className="btn btn-dialog btn-primary btn-me btn-user-activate" onClick={handleActivate}>Yes</button>
                            <button className="btn btn-dialog btn-secondary" onClick={handleCancelActivate}>No</button>
                        </div>
                    </div>
                </div>
                <div className="dialog-overlay dialog-overlay-deactivate hide">
                    <div className="dialog-container">
                        <button className="dialog-close-button font-big" onClick={handleCancelDeactivate}>
                            <i className="fa-solid fa-xmark"></i>
                        </button>
                        <p className="dialog-title col-rps-light mt-0 mb-0">Deactivate Account</p>
                        <p className="dialog-content">Are you sure wish to deactivate <span className="prefered-name-section"></span>'s account?</p>
                        <div className="dialog-buttons float-right">
                            <button className="btn btn-dialog btn-primary btn-me btn-user-deactivate" onClick={handleDeactivate}>Yes</button>
                            <button className="btn btn-dialog btn-secondary" onClick={handleCancelDeactivate}>No</button>
                        </div>
                    </div>
                </div>
            <NewUser showDialog={mainContext.ShowAddUser}/>
            </div>
        </>
    );
}

export default UserManagement;
