import React, { useState, useEffect } from 'react';

import "./HomePage.scss";
import Header from "../../components/header/Header";
import CustomNavLink from "../../components/shared/CustomNavLink";
import { Outlet, useMatch  } from "react-router-dom";
import { useMainContext } from "../../dataproviders/MainDataProvider";

function HomePage() {
    const mainContext = useMainContext();
    const isProject = useMatch("/projects");
    const isUser = useMatch("/users");
    const [isUserExpand, setIsUserExpand] = useState(false); // Initialize the mobile screen state

    useEffect(() => {
        const _auth = JSON.parse(sessionStorage.getItem("auth"));

        if (!!_auth) {
            const role = _auth.role;

            //0:Admin, 1:Operator, 2:Project Manager, 3:Client
            if (role === 0 || role === 2) {
                setIsUserExpand(true);
            }
        }

    }, []);

    const handleNewUser = () => {
        mainContext.HandleAddUser(true);
    };

    const handleNewProject = () => {
        mainContext.HandleAddProject(true);
        mainContext.SetProjectMode("new");
        mainContext.SetProjectId("");
    };

    return (
        <div className="dashboard-container">
            <Header />
            <div className="home-container">
                <div className="content-container">
                    <div className="title-container">
                        <div className="title">Neptune Management</div>
                        <div className="sub-title">
                            All information on confirmed projects
                        </div>
                    </div>
                    <div className="action-container"></div>
                    <div className="tab-container">
                        <div className="tab-button-list">
                            {isProject && isUserExpand &&
                                <button className="btn btn-secondary" onClick={handleNewProject}>
                                    New Project
                                </button>
                            }
                            {isUser &&
                                <button className="btn btn-secondary" onClick={handleNewUser}>
                                    New User
                                </button>
                            }
                        </div>
                        <div className="tab-list">
                            <CustomNavLink to="/projects" text="Projects" />
                            {isUserExpand && <CustomNavLink to="/users" text="Users" /> }
                        </div>
                        <div className="tab-content">
                            <Outlet />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomePage;