import React from "react";
import "./LoginPage.scss";

import { Outlet } from "react-router-dom/dist";

function LoginPage() {
  return (
    <div className="login">
      <div className="login-container">
        <Outlet />
      </div>
    </div>
  );
}

export default LoginPage;
