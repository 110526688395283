import axios from "axios";
import { baseUrl } from "../constants";
//import { setBearerAuthorizationHeader } from "../../utils/interceptors";

const GetVessels = () => {
  return axios
    .get(`${baseUrl()}/Vessel/GetVessels`)
    .then((data) => {
      return data;
    })
      .catch((error) => {
          if (error.response.status === 307) {
              // Handle the specific status code 307 - Status307TemporaryRedirect
              const errResponse = error.response.data;
              let newToken = errResponse.token;

              const _auth = JSON.parse(sessionStorage.getItem("auth"));
              _auth.token = newToken;
              sessionStorage.setItem("auth", JSON.stringify(_auth));

              return axios
                  .get(`${baseUrl()}/Vessel/GetVessels`)
                  .then((data) => {
                      return data;
                  })
                  .catch((error) => {
                      console.error("ProjectServices: Get Vessels API", error);
                  });
          } else {
              console.error("ProjectServices: Get Vessels API", error);
          }
    });
};

const VesselServices = { GetVessels };

export default VesselServices;
