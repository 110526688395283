import React from "react";
import { createHashRouter } from "react-router-dom";
import LoginPage from "../pages/Login/LoginPage";
import HomePage from "../pages/Home/HomePage";
import AuthDataProvider from "../dataproviders/AuthDataProvider";
import MainDataProvider from "../dataproviders/MainDataProvider";
import LoginForm from "../components/login/LoginForm";
import ResetPassword from "../components/login/ResetPassword";
import ProjectList from "../components/project/ProjectList";
import ProjectDataProvider from "../dataproviders/ProjectDataProvider";
import UserManagement from "../components/user-management/UserManagement";
import UserDataProvider from "../dataproviders/UserDataProvider";
import ToastWrapper from "../pages/ToastWrapper/ToastWrapper";

const Routers = createHashRouter([
  {
    element: <AuthDataProvider />,
    children: [
        {
            element: <ToastWrapper />,
            children: [
                {
                    element: <MainDataProvider />,
                    children: [
                        {
                            path: "/",
                            element: <HomePage />,
                            children: [
                                {
                                    path: "projects",
                                    element: <ProjectDataProvider />,
                                    children: [
                                        {
                                            path: "",
                                            element: <ProjectList />,
                                        },
                                    ],
                                },
                                {
                                    path: "users",
                                    element: <UserDataProvider />,
                                    children: [
                                        {
                                            path: "",
                                            element: <UserManagement />,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                {
                    path: "/login",
                    element: <LoginPage />,
                    children: [
                        {
                            path: "",
                            element: <LoginForm />,
                        },
                        {
                            path: "resetpassword",
                            element: <ResetPassword />,
                        },
                    ],
                },
            ]
        }
    ],
  },
]);

export default Routers;
