import React, { useState } from "react";
import { Outlet } from "react-router-dom";

const UserContext = React.createContext(null);

export const useUserContext = () => {
  return React.useContext(UserContext);
};

function UserDataProvider() {
  const [refreshFlag, setRefreshFlag] = useState(false);


  return (
    <UserContext.Provider 
    value={{ 
      RefreshFlag: refreshFlag,
      SetRefreshFlag: setRefreshFlag,
    }}>
      <Outlet />
    </UserContext.Provider>
  );
}

export default UserDataProvider;
