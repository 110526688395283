import axios from "axios";
// import { encode, decode } from "base-64";

//export const setBearerAuthorizationHeader = (token) => {
//  axios.interceptors.request.use((config) => {
//      config.headers.Authorization = `Bearer ${token}`;
//    return config;
//  });
//};

export const setDefaultInterceptor = () => {
  axios.interceptors.request.use(
      (config) => {
          const _auth = JSON.parse(sessionStorage.getItem("auth"));

          if (!!_auth) {
              const token = _auth.token;
              config.headers.Authorization = `Bearer ${token}`;
          }

      return config;
    },
    (error) => {
      //   toast.error("Something went wrong. Please try again later.");
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      //   toast.error("Something went wrong. Please try again later.");
      return Promise.reject(error);
    }
  );
};
