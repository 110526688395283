import React, { useState, useEffect, useCallback } from "react";
import "./ProjectList.scss";
import ProjectServices from "../../services/api/ProjectServices";
import ProjectDetail from "./ProjectDetail"
import { useMainContext } from "../../dataproviders/MainDataProvider";
import { useProjectContext } from "../../dataproviders/ProjectDataProvider";
import { useAuthContext } from '../../dataproviders/AuthDataProvider';

function ProjectList() {
  const mainContext = useMainContext();
  const projectContext = useProjectContext();
  const authContext = useAuthContext();
  const [data, setData] = useState([]);
  const [activeProject, setActiveProject] = useState(null);
  const [modifyProject, setModifyProject] = useState(null);
  const [deleteProjectDialog, setDeleteProjectDialog] = useState(null);
  const [activeVessel, setActiveVessel] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
      if(projectContext.RefreshFlag)
      {
        getProjects();
        projectContext.SetRefreshFlag(false);
      }
      return () => {};
  }, [projectContext.RefreshFlag, projectContext]);

  useEffect(() => {
    const _auth = JSON.parse(sessionStorage.getItem("auth"));

    if (!!_auth) {
        const role = _auth.role;

        //0:Admin, 1:Operator, 2:Project Manager, 3:Client
        if (role === 0 || role === 2) {
            setIsAdmin(true);
        }
    }

}, []);
  const getProjects = async () => {
    const result = await ProjectServices.GetProjects();

    if (!!result) {
      setData(result.data);
    }
  };

  const handleProjectAccordionClick = (e, index) => {
      if (e.currentTarget !== e.target) return;
      setActiveProject((prevIndex) => (prevIndex === index ? null : index));
      setActiveVessel(null); // Reset active vessel when toggling projects
      setModifyProject(null); // Reset dropdown menu when toggling projects
  };

  const handleProjectAccordionModifyClick = (index) => {
      setModifyProject((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleVesselAccordionClick = (index) => {
      setActiveVessel((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleViewProject = (projectid,index) => {
    mainContext.SetProjectId(projectid);
    mainContext.HandleAddProject(true);
    mainContext.SetProjectMode("view");
    handleProjectAccordionModifyClick(index)
  }

  const handleEditProject = (projectid,index) => {
    mainContext.SetProjectId(projectid);
    mainContext.HandleAddProject(true);
    mainContext.SetProjectMode("edit");
    handleProjectAccordionModifyClick(index)
  }


  // Group the data by projects
  const groupedData = data.reduce((acc, item) => {
    const { ProjectId, ProjectName, VesselId, VesselName, DeviceId, DeviceName, DeviceUrl } = item;
    const existingProjectIndex = acc.findIndex((group) => group.ProjectId === ProjectId);

    if (existingProjectIndex === -1) {
      acc.push({ ProjectId, ProjectName, Vessels: [{ VesselId, VesselName, Devices: [{ DeviceId, DeviceName, DeviceUrl }] }] });
    } else {
      const existingVesselIndex = acc[existingProjectIndex].Vessels.findIndex((v) => v.VesselId === VesselId);
      if (existingVesselIndex === -1) {
        acc[existingProjectIndex].Vessels.push({ VesselId, VesselName, Devices: [{ DeviceId, DeviceName, DeviceUrl }] });
      } else {
        acc[existingProjectIndex].Vessels[existingVesselIndex].Devices.push({ DeviceId, DeviceName, DeviceUrl });
      }
    }

    return acc;
  }, []);


    const handleDeleteProject = useCallback((index) => {
        setModifyProject(null); // Reset dropdown menu when toggling projects
        setDeleteProjectDialog((prevIndex) => (prevIndex === index ? null : index));
    }, []);

    const handleConfirmDelete = async (e) => {
        const cancelButton = e.currentTarget;

        try {
            // Disable the button and add the loading class
            cancelButton.disabled = true;
            cancelButton.innerHTML = '';
            cancelButton.classList.add('loading');

            let index = cancelButton.getAttribute('projectid');
            let projectName = cancelButton.getAttribute('projectname');

            const flag = await ProjectServices.DeleteProject(index);

            if (!!flag) {
                handleError('Success', projectName + " has been successfully deleted.");
            }
            else {
                // Display error message for network errors
                handleError('Error', projectName + ' has failed to delete. Please try again.');
            }
        }
        catch (error) {
            // Display error message for network errors
            handleError('Error', 'An error occurred. Please try again later.');
        }
        finally {
            // Enable the button and remove the loading class
            cancelButton.disabled = false;
            cancelButton.innerHTML = 'Yes';
            cancelButton.classList.remove('loading');

            setDeleteProjectDialog(null); // Reset dialog

            const result = await ProjectServices.GetProjects();

            if (!!result) {
                setData(result.data);
            }
        }
    };

    const handleCancelDelete = useCallback((index) => {
        setDeleteProjectDialog(null); // Reset dialog
    }, []);

    const handleError = (errorType, errorMessage) => {
        authContext.HandleError(errorType, errorMessage);
    }

  return( 
    <div className="project-container">
      {groupedData.map((group, index) => (
          <div key={index} className={`accordion-section ${activeProject === index ? 'accordion-section-show' : 'accordion-section-not-show'}`}>
          {/* Project level accordion */}
          <div
            className={`accordion-header-section accordion-title accordion ${activeProject === index ? 'active' : ''}`}
            onClick={(event) => handleProjectAccordionClick(event, index)}
          >
          <div className="context-menu dropdown-dropdown">
                <button className={`dropdown-toggle dropdown-toggle-accordion ${modifyProject === index ? 'col-rps' : ''}`} type="button" onClick={() => handleProjectAccordionModifyClick(index)}><i className="ms-0 fa-solid fa-ellipsis-vertical"></i></button>
               {modifyProject === index && (
                   <div className="dropdown-menu dropdown-menu-accordion show">
                        <button className="dropdown-item" onClick={() => handleViewProject(group.ProjectId,index)}><i className="fa-regular fa-eye"></i> View Project</button>
                        {isAdmin && (<button className="dropdown-item" onClick={() => handleEditProject(group.ProjectId,index)}><i className="fa-light fa-pencil"></i> Edit Project</button>)} 
                        {isAdmin && (<button className="dropdown-item dropdown-item-top-border" onClick={() => handleDeleteProject(index)}><i className="fa-regular fa-trash-can"></i> Delete</button>)}
                   </div>
               )}
           </div>
           <span className="flex-left"onClick={(event) => handleProjectAccordionClick(event, index)}>{group.ProjectName}</span>
            <i className={`fas ${activeProject === index ? 'fa-chevron-up' : 'fa-chevron-down'}`} onClick={(event) => handleProjectAccordionClick(event, index)}></i>
          </div>
            {deleteProjectDialog === index && (
                <div className="dialog-overlay">
                    <div className="dialog-container">
                        <button className="dialog-close-button font-big" onClick={() => handleCancelDelete(index)}>
                            <i className="fa-solid fa-xmark"></i>
                        </button>
                        <p className="dialog-title col-rps-light mt-0 mb-0">Delete Project</p>
                        <p className="dialog-content">Are you sure wish to delete {group.ProjectName}?</p>
                          <div className="dialog-buttons float-right">
                              <button className="btn btn-dialog btn-primary btn-me" projectid={group.ProjectId} projectname={group.ProjectName} onClick={handleConfirmDelete}>Yes</button>
                            <button className="btn btn-dialog btn-secondary" onClick={() => handleCancelDelete(index)}>No</button>
                        </div>
                    </div>
                </div>
            )}
          <div className={`panel ${activeProject === index ? 'show' : ''}`}>
            {/* Content inside Project */}
            {group.Vessels.map((vesselItem, vesselIndex) => (
              <div key={vesselIndex}>
                {/* Vessel level accordion */}
                <button
                  className={`accordion vessel-accordion ${activeVessel === vesselIndex && activeProject === index ? 'active' : ''}`}
                  onClick={() => handleVesselAccordionClick(vesselIndex)}
                >
                 {vesselItem.VesselName}
                  <i className={`fas ${activeVessel === vesselIndex && activeProject === index ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
                </button>
                <div className={`panel ${activeVessel === vesselIndex && activeProject === index ? 'show' : ''}`}>
                  {/* Content inside Vessel */}
                  {vesselItem.Devices.map((deviceItem, deviceIndex) => (
                      <div key={deviceIndex} className="project-vessel-device"><a className="link" target="_blank" rel="noopener noreferrer" href={deviceItem.DeviceUrl}>{deviceItem.DeviceName}</a></div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    <ProjectDetail />
    </div>
  );
}

export default ProjectList;
