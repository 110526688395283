import React, { useState, useEffect, useRef } from "react";
import "./ProjectDetail.scss";
import ProjectServices from "../../services/api/ProjectServices";
import VesselServices from "../../services/api/VesselServices";
import UserServices from "../../services/api/UserServices";
import { useMainContext } from "../../dataproviders/MainDataProvider";
import { useAuthContext } from "../../dataproviders/AuthDataProvider";
import { useProjectContext } from "../../dataproviders/ProjectDataProvider";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { addHours, addMinutes, addSeconds } from 'date-fns';

function ProjectDetail(props) {
  const mainContext = useMainContext();
  const authContext = useAuthContext();
  const projectContext = useProjectContext();
  const [viewOnly, setViewOnly] = useState(false);
  const [pageTitle, setPageTitle] = useState('');
  const [disableStartDate, setDisableStartDate] = useState(false);
  const [initialData, setInitialData] = useState([]);
  const deploymentContentRef = useRef(null);
  const [hasScrollbar, setHasScrollbar] = useState(false);
  const [dropdownContentVesselWidth, setDropdownContentVesselWidth] = useState(0);
  const [dropdownContentVesselLeft, setDropdownContentVesselLeft] = useState(0);
  const [dropdownContentVesselMarginTop, setDropdownContentVesselMarginTop] = useState(8);
  const [dropdownContentUserWidth, setDropdownContentUserWidth] = useState(0);
  const [dropdownContentUserLeft, setDropdownContentUserLeft] = useState(0);
  const [dropdownContentUserMarginTop, setDropdownContentUserMarginTop] = useState(8);
  const [isLoading, setIsLoading] = useState(true);

  //Project Data / Overall methods
  //=========================================================================================================
  const [projectData, setProjectData] = useState({
    projectname: '',
    projectstartdate: '',
    projectenddate: '',
    projectdeployments: [],
  });

  const [validationErrors, setValidationErrors] = useState({
    projectname: '',
    projectstartdate: '',
    projectenddate:'',
    vessels: [],
    users: [],
  });

  const handleStartDateChange = (date) => {
    setProjectData((prevProjectData) => ({
      ...prevProjectData,
      projectstartdate: date,
    }));
          
    setValidationErrors((prevValidationErrors) => ({
      ...prevValidationErrors,
      projectstartdate: '',
    }));
    
  };

  const handleEndDateChange = (date) => {
    setProjectData((prevProjectData) => ({
      ...prevProjectData,
      projectenddate: date,
    }));

    setValidationErrors((prevValidationErrors) => ({
      ...prevValidationErrors,
      projectenddate: '',
    }));
  };

  const handleOnFocus = (event,index) => {
    const { name } = event.target;

    //Hide dropdown when lost focus
    if(name !== 'btnVessel' || (name === 'btnVessel' && previousVesselIndex !== index))
    {
      hideVesselDropdown(previousVesselIndex);
    }

    //Hide dropdown when lost focus
    if(name !== 'btnUser' || (name === 'btnUser' && previousVesselIndex !== index))
    {
      hideUserDropdown(previousVesselIndex);
    }

  };

  const handleValidation = () => {
     const errors = {};

     if (!projectData.projectname) {
       errors.projectname = 'Project title is required.';
     }
 
     if (!projectData.projectstartdate) {
       errors.projectstartdate = 'Start date is required.';
     }
 
     if (!projectData.projectenddate) {
       errors.projectenddate = 'End date is required.';
     }
 
     if(projectData.projectstartdate && projectData.projectenddate && (projectData.projectstartdate > projectData.projectenddate)) {
         errors.projectstartdate = 'Start date cannot be later than end date'
     }
     const projectdeployments =  [...vessels];
     const vesselErrors  = projectdeployments.map((vessel, i) => {
      const errormsg = vessel.vesselid.length === 0
        ? "Vessel is required."
        : ""
      return errormsg;
    });

    const vesselIdMap = new Map();
    const duplicateVesselErrors = projectdeployments.map((vessel, index) => {
      
      if (vessel.vesselid !== '' && vesselIdMap.has(vessel.vesselid)) {
        return "Duplicate vessel entries. Each vessel should only be entered once.";
      } else {
        vesselIdMap.set(vessel.vesselid, true);
        return "";
      }
    });
  
    const userErrors  = projectdeployments.map((vessel, i) => {
      const errormsg = vessel.users.length === 0
        ? "PAM Operator is required."
        : ""
      return errormsg;
    });

    if(userErrors.some(Boolean)){
      errors.users = userErrors;
    }

    if(duplicateVesselErrors.some(Boolean)){
      errors.vessels = duplicateVesselErrors;
    }
    
    //Required validatiion will overwrite duplicate validation
    if(vesselErrors.some(Boolean)){
      errors.vessels = vesselErrors;
    }
    
     setValidationErrors(errors);
     return (Object.keys(errors).length === 0);
  }

  const handleSaveProject = (e) => {
    const validationResult = handleValidation(); 
  
    //Proceed to save if there is no error
    if (validationResult) {
      const deployment = [...vessels];

      //Convert datetime to UTC
      const startDateUtc = addHours(projectData.projectstartdate, -projectData.projectstartdate.getTimezoneOffset() / 60);
      let endDateUtc = addHours(projectData.projectenddate, -projectData.projectenddate.getTimezoneOffset() / 60);
      endDateUtc = addHours(endDateUtc, 23);
      endDateUtc = addMinutes(endDateUtc, 59);
      endDateUtc = addSeconds(endDateUtc, 59);

      const updatedProjectData = {
        ...projectData,
        projectdeployments: deployment,
        projectstartdate: startDateUtc,
        projectenddate: endDateUtc,
      };
  
      const hasDataChanged = JSON.stringify(initialData) !== JSON.stringify(updatedProjectData);
      const hasDeploymentChanged = JSON.stringify(initialData.projectdeployments) !== JSON.stringify(updatedProjectData.projectdeployments);

      if(hasDataChanged)
      {
        saveProject(updatedProjectData, hasDeploymentChanged);
      }
      else
      {
        //no data change. show success notification without calling API
        handleNotification('Success', "The project has now been saved.");
        handleCancel();
      }
    }
  };

  const saveProject = async (projectdetail, hasDeploymentChanged) => {
    const cancelButton = document.getElementById("btnSaveProject");
    const systemError = "An error occurred. Please try again later."
      try {
          // Disable the button and add the loading class
          cancelButton.disabled = true;
          cancelButton.innerHTML = '';
          cancelButton.classList.add('loading');

          let result = null;
          if(mainContext.ProjectMode === "new"){
              result =  await ProjectServices.AddProject(projectdetail);
          }
          else {
              result =  await ProjectServices.UpdateProject(projectdetail, hasDeploymentChanged);
          }
          
          if (!!result) {
              if (result.data.status) {
                  handleNotification('Success', "The project has now been saved.");
                  projectContext.SetRefreshFlag(true);
                  handleCancel();
              }
              else {
                if (result.data.errortype === 0) {
                  //Handle system error
                  handleNotification('Error', systemError);
                  handleCancel();
                }
                else{
                  //Handle validation error
                  const { errormessages } = result.data;
                  const newValidationErrors = { ...validationErrors };
                  
                  errormessages.forEach((errorMessage) => {
                    const { message, index } = errorMessage;
                    if (index === -1) {
                      newValidationErrors.projectname = message;
                    } else {
                      if (!newValidationErrors.vessels[index]) {
                        newValidationErrors.vessels[index] = [];
                      }
                      if(newValidationErrors.vessels[index].length ===0){
                        newValidationErrors.vessels[index].push(message);
                      }                  
                    }
                  });
                  
                  setValidationErrors(newValidationErrors);
                }
              }
          }
          else {
            handleNotification('Error', systemError);
              handleCancel();
          }

          
      }
      catch (error) {
          // Display error message for network errors
          handleNotification('Error', systemError);
          handleCancel();
      }
      finally {
          // Enable the button and remove the loading class
          cancelButton.disabled = false;
          cancelButton.innerHTML = 'Save';
          cancelButton.classList.remove('loading');
      }
  };

  const handleCancel = () => {
      resetForm();

      //Close dialog
      mainContext.HandleAddProject(false);
  };

  const handleNotification = (errorType, errorMessage) => {
      authContext.HandleError(errorType, errorMessage);
  };

  const resetForm = () => {
    setProjectData({
      projectname: '',
      projectstartdate: '',
      projectenddate: '',
      projectdeployments: [],
    });

    setVessels([]);

    setValidationErrors({
      projectname: '',
      projectstartdate: '',
      projectenddate: '',
      vessels: [],
      users: [],
    });

    //Reset dropdown search value
    handleResetSearchInputVessel();
    handleResetSearchInputUser();

    //Hide all dropdown
    setVesselDropdownStates([]);
    setUserDropdownStates([]);
  };

  const handleInputChange = (event) => {
    const fieldName = event.target.dataset.field;
    const { name, value } = event.target;

    setProjectData((prevProjectData) => ({
      ...prevProjectData,
      [name]: value,
    }));
    

    setValidationErrors((prevValidationErrors) => ({
      ...prevValidationErrors,
      [name]: value ? '' : `${fieldName} is required.`,
    }));
  };

  useEffect(() => {
    async function fetchData() {
      if(mainContext.ShowProjectDetail)
      {
        //load dropdown list
        const [vessels, operators] = await Promise.all([getVessels(), getPamOperators()]);
        setDisableStartDate(false);

        if(mainContext.ProjectMode === "view" || mainContext.ProjectMode === "edit"){
          const result = await ProjectServices.GetProjectDetail(mainContext.ProjectId);
         
          if (!!result) {
            const startDateLocal = new Date(result.data.projectstartdate);
            const endDateLocal = new Date(result.data.projectenddate);
            let data = { ...result.data };

            data.projectstartdate = startDateLocal;
            data.projectenddate = endDateLocal;
            setInitialData(data)

            //Convert datetime to UTC without time
            const startDateUtc = addHours(startDateLocal, startDateLocal.getTimezoneOffset() / 60);
            let endDateUtc = addHours(endDateLocal, endDateLocal.getTimezoneOffset() / 60);
            endDateUtc = addHours(endDateUtc, -23);
            endDateUtc = addMinutes(endDateUtc, -59);
            endDateUtc = addSeconds(endDateUtc, -59);
   
            result.data.projectstartdate = startDateUtc;
            result.data.projectenddate = endDateUtc;
            
            //TODO: test on same day scenario
            if(projectData.projectstartdate <= new Date())
            {
              setDisableStartDate(true);
            }

            setProjectData(result.data);
            setVessels(result.data.projectdeployments);
          }
         
        }
        setIsLoading(false);
      }
    }
    setIsLoading(true);
    fetchData();

    const title = mainContext.ProjectMode.charAt(0).toUpperCase() + mainContext.ProjectMode.slice(1);
    setPageTitle(title);

  }, [mainContext.ShowProjectDetail]);

  useEffect(() => {
    setViewOnly(mainContext.ProjectMode === "view")

  }, [mainContext.ProjectMode]);

  //Vessel Data
  //=========================================================================================================
  const [vessels, setVessels] = useState([]);
  const [vesselDropdownStates, setVesselDropdownStates] = useState([]);
  const [previousVesselIndex, setPreviousVesselIndex] = useState(0);
  const [vesselList, setVesselList] = useState([]);
  const [searchInputVessel, setSearchInputVessel] = useState('');
  const [filteredVessels, setFilteredVessels] = useState(vesselList);

  // const vesselList = [
  //   { vesselid: 'FC762E2D-1464-4A3C-B756-AB955D76CA85', vesselname: 'Vessel 1' },
  //   { vesselid: '52E3A0D8-1112-4649-9460-46744EF00880', vesselname: 'Vessel 2' },
  //   { vesselid: '65220B80-823B-480E-BB77-E968B0B6B310', vesselname: 'Vessel 3' },
  // ];

  const getVessels = async () => {
    const result = await VesselServices.GetVessels();
    if (!!result) {
      setVesselList(result.data);
    }
  };

  const handleAddVessel = () => {
    const newVessel = {
      vesselid: '',
      users: [],
    };
    setVessels([...vessels, newVessel]);
  };

  const handleRemoveVessel = (index) => {
    if(viewOnly === false)
    {
      const updatedVessels = vessels && vessels.length ? vessels.filter((_, i) => i !== index) : [];
      setVessels(updatedVessels);

      const updatedVesselErrors = validationErrors.vessels && validationErrors.vessels.length ? validationErrors.vessels.filter((_, i) => i !== index) : [];
      const updatedUsersErrors = validationErrors.users && validationErrors.users.length ? validationErrors.users.filter((_, i) => i !== index) : [];

      setValidationErrors((prevValidationErrors) => ({
        ...prevValidationErrors,
        vessels: updatedVesselErrors,
        users: updatedUsersErrors,
      }));
  
      //Reset dropdown search value
      handleResetSearchInputVessel();
      handleResetSearchInputUser();
  
      //Hide all dropdown
      setVesselDropdownStates([]);
      setUserDropdownStates([]);
    }
  };

  const handleVesselOptionChange = (index, id, desc) => {
    //Update change into vessel state
    const updatedVessels = vessels.map((vessel, i) => {
      if (i === index) {
        return { ...vessel, vesselid: id };
      }
      return vessel;
    });
    setVessels(updatedVessels);

    //Remove error message
    const updatedVesselErrors  = validationErrors.vessels.map((error, i) => {
      if (i === index) {
        return '';
      }
     return error;
   });

   setValidationErrors((prevValidationErrors) => ({
      ...prevValidationErrors,
      vessels: updatedVesselErrors,
    }));
  };
  
  const toggleVesselDropdown = (index) => {
    if(viewOnly === false){
      const newStates = [...vesselDropdownStates];
      newStates[index] = !newStates[index];
      setVesselDropdownStates(newStates);
      setPreviousVesselIndex(index);
      handleResetSearchInputVessel();
    }
  };
  
  const hideVesselDropdown = (index) => {
    const newStates = [...vesselDropdownStates];
    newStates[index] = false;
    setVesselDropdownStates(newStates);
  };

  const handleOptionClick = (id, description, index) => {
    handleVesselOptionChange(index, id, description)
    hideVesselDropdown(index); // Hide the dropdown after an option is selected
  };

  const isOptionSelected = (id,index) => {
    return id === vessels[index].vesselid ? 'selected' : '';
  };
  
  const renderSelectTag = (id,index) => {
   
    if (id === vessels[index].vesselid) {
        return (
            <span className="select-tag float-right">
                <i className="fa-solid fa-check "></i>
            </span>
        );
    }
    return (
        <span className="select-tag">
        </span>
    );
  };

  const handleSearchInputChangeVessel = (event) => {
    const searchValue = event.target.value;
    setSearchInputVessel(searchValue);
  
    const filteredVessels = vesselList.filter((vessel) =>
      vessel.vesselname.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredVessels(filteredVessels);
  };

  const handleResetSearchInputVessel = () => {
    setSearchInputVessel('');
    setFilteredVessels(vesselList);
  };

  //User Data
  //=========================================================================================================
  const [userDropdownStates, setUserDropdownStates] = useState([]);
  const [userList, setUserList] = useState([]);
  const [searchInputUser, setSearchInputUser] = useState('');
  const [filteredUsers, setFilteredUsers] = useState(userList);

  // const userList = [
  //   { userid: '1', preferedname: 'John Doe' },
  //   { userid: '2', preferedname: 'James Dean' },
  //   { userid: '3', preferedname: 'Andy Low' },
  //   { userid: '4', preferedname: 'Abdd Defg' },
  //   { userid: '5', preferedname: 'Higjl Lmanndd' },
  //   { userid: '6', preferedname: 'Oppp qrsty' },
  //   { userid: '7', preferedname: 'Vaaswer Dafas' },
  //   { userid: '8', preferedname: 'Sssafs Fasfdsa' },
  //   { userid: '9', preferedname: 'Hasfdsa Lasss' },
  // ];

  const getPamOperators = async () => {
    const result = await UserServices.GetPamOperators();
    if (!!result) {
      setUserList(result.data);
    }
  };

  const removeSelectedItem = (itemId,index) => {
    if(viewOnly === false) {
      const updatedVessels = vessels.map((vessel, i) => {
        if (i === index) {
          const updatedUsers = vessel.users.filter(user => user.userid !== itemId);
    
          const updatedVessel = {
            ...vessel, // Copy all existing properties
            users: updatedUsers, // Update the users property
          };
    
          return updatedVessel; // Return the updated vessel
        }
    
        return vessel; // Return unchanged vessel for other indices
      });
    
      setVessels(updatedVessels); // Set the state with updated vessels after the loop
    }
  };

  const toggleUserDropdown = (index) => {
    if(viewOnly === false){
      const newStates = [...userDropdownStates];
      newStates[index] = !newStates[index];
      setUserDropdownStates(newStates);
      setPreviousVesselIndex(index);
      handleResetSearchInputUser();
    }
  };
  
  const toggleSelectAll = (index) => {
    const updatedVessels = vessels.map((vessel, i) => {
      if (i === index) {
        const updatedUsers = (vessel.users.length === userList.length)
          ? []
          : userList.map(item => ({ userid: item.userid })); // Add the itemId to users array
  
        const updatedVessel = {
          ...vessel, // Copy all existing properties
          users: updatedUsers, // Update the users property
        };
  
        return updatedVessel; // Return the updated vessel
      }
  
      return vessel; // Return unchanged vessel for other indices
    });
  
    setVessels(updatedVessels); // Set the state with updated vessels after the loop
  };

    const handleCheckboxChange = (itemId, index) => {
    const updatedVessels = vessels.map((vessel, i) => {
      if (i === index) {
        const updatedUsers = vessel.users.some(a=> a.userid === itemId)
          ? vessel.users.filter(a => a.userid !== itemId)
          : [...vessel.users, { userid: itemId }]; // Add the itemId to users array
  
        const updatedVessel = {
          ...vessel, // Copy all existing properties
          users: updatedUsers, // Update the users property
        };
  
        return updatedVessel; // Return the updated vessel
      }
  
      return vessel; // Return unchanged vessel for other indices
    });

    setVessels(updatedVessels); // Set the state with updated vessels after the loop

        //Remove error message
        const updatedUserErrors  = validationErrors.users.map((error, i) => {
          if (i === index) {
            return '';
          }
         return error;
       });
    
       setValidationErrors((prevValidationErrors) => ({
          ...prevValidationErrors,
          users: updatedUserErrors,
        }));
  };

  const hideUserDropdown = (index) => {
    const newStates = [...userDropdownStates];
    newStates[index] = false;
    setUserDropdownStates(newStates);
  };

  const handleSearchInputChangeUser = (event) => {
    const searchValue = event.target.value;
    setSearchInputUser(searchValue);
  
    const filteredUsers = userList.filter((user) =>
      user.preferedname.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredUsers(filteredUsers);
  };

  const handleResetSearchInputUser = () => {
    setSearchInputUser('');
    setFilteredUsers(userList);
  };

 //=========================================================================================================

    useEffect(() => {
        const checkScrollbar = (isScroll) => {
            if (isScroll) {
                hideVesselDropdown(previousVesselIndex);
                hideUserDropdown(previousVesselIndex);
            }

            if (deploymentContentRef.current) {
                const hasVerticalScrollbar = deploymentContentRef.current.scrollHeight > deploymentContentRef.current.clientHeight;
                setHasScrollbar(hasVerticalScrollbar);

                const btnVesselElement = document.getElementById("btnVessel");
                const labelVesselElement = document.querySelector('.label-dropdown-vessel');
                const dialogProjectElement = document.querySelector('.dialog-container-project');
                const newScrollTop = deploymentContentRef.current.scrollTop;

                if (dialogProjectElement) {
                    const computedStyles = window.getComputedStyle(dialogProjectElement);
                    const leftPadding = computedStyles.getPropertyValue('padding-left');
                    const numericLeftPadding = parseInt(leftPadding, 10);

                    if (btnVesselElement && labelVesselElement) {
                        let width = btnVesselElement.getBoundingClientRect().width;
                        let labelWidth = labelVesselElement.getBoundingClientRect().width;

                        setDropdownContentVesselLeft(labelWidth + numericLeftPadding);
                        setDropdownContentVesselWidth(width);
                        if (newScrollTop > 0) setDropdownContentVesselMarginTop(-newScrollTop + 8);
                        else setDropdownContentVesselMarginTop(8);
                    }

                    const btnUserElement = document.getElementById("btnUser");
                    const labelUserElement = document.querySelector('.label-dropdown-user');

                    if (btnUserElement && labelUserElement) {
                        let width = btnUserElement.getBoundingClientRect().width;
                        let labelWidth = labelUserElement.getBoundingClientRect().width;

                        setDropdownContentUserLeft(labelWidth + numericLeftPadding);
                        setDropdownContentUserWidth(width);
                        if (newScrollTop > 0) setDropdownContentUserMarginTop(-newScrollTop + 8);
                        else setDropdownContentUserMarginTop(8);
                    }
                }
            }
        };

        // Initially check for scrollbar
        checkScrollbar();

        // Recheck whenever the window is resized or the content changes
        window.addEventListener('resize', checkScrollbar);

        if (deploymentContentRef.current) {
            deploymentContentRef.current.addEventListener('DOMSubtreeModified', (event) => {
                checkScrollbar(false); // Pass your custom argument here
            });
            deploymentContentRef.current.addEventListener('scroll', (event) => {
                checkScrollbar(true); // Pass your custom argument here
            });
        }

        return () => {
            window.removeEventListener('resize', checkScrollbar);
            if (deploymentContentRef.current) {
                deploymentContentRef.current.removeEventListener('DOMSubtreeModified', checkScrollbar);
                deploymentContentRef.current.removeEventListener('scroll', checkScrollbar);
            }
        };
    }, [isLoading]);

  return( 
    <>
      <div className={`dialog-overlay dialog-overlay-new-project ${mainContext.ShowProjectDetail ? '' : 'hidden'}`}>
      {isLoading && (<div className="dialog-container-blank"></div>)}
      {!isLoading && (<div className="dialog-container dialog-container-long dialog-container-project">
          <button className="dialog-close-button" onClick={handleCancel} >
                <i className="fa-solid fa-xmark"></i>
          </button>
          <p className="dialog-title col-rps-light mt-0 mb-0">{pageTitle} Project</p>
          <div>
          <div className="dialog-content">
            <div className="mb-2">
              <div className="d-flex align-items-center">
              <label htmlFor="txtProjectName" className="w-34 required">Project Title</label>
              <input id="txtProjectName" 
              className={`${validationErrors.projectname ? 'error' :''} ${viewOnly ? 'disabled' :''} w-66`} 
              type="text"  
              name="projectname"  
              data-field="Project title"
              value={projectData.projectname} 
              onChange={handleInputChange}
              onFocus={handleOnFocus}
              disabled={viewOnly}
              required
              />
              </div>
              {validationErrors.projectname && (
                  <label className="error error-message">
                        <i className="fa-solid fa-circle-info"></i> {validationErrors.projectname}
                    </label>
              )}
            </div>
            <div className="mb-2">
            <div className="d-flex align-items-center input-label-group-date">
              <label htmlFor="dtStartDate" className="w-34 required">Start Date</label>
                 <div className="datepicker-wrapper w-66">
                 {(
                    <DatePicker
                      id="dtStartDate"
                      name="startdate"
                      selected={projectData.projectstartdate}
                      onChange={handleStartDateChange}
                      onFocus={handleOnFocus}
                      minDate={new Date()}
                      dateFormat="dd/MM/yyyy" // Customize the date format if needed
                      className={`${validationErrors.projectstartdate ? 'error' :''} ${viewOnly || disableStartDate ? 'disabled' :''} `}
                      disabled={viewOnly || disableStartDate }
                    />
                  )}
                  </div>
            </div>
              {validationErrors.projectstartdate && (
            <label className="error error-message">
            <i className="fa-solid fa-circle-info"></i> {validationErrors.projectstartdate}
        </label>
          )}
            </div>
            <div className="mb-2">
                <div className="d-flex align-items-center input-label-group-date">
                  <label htmlFor="dtEndDate" className="w-34 required">End Date</label>
                   <div className="datepicker-wrapper w-66">
                 {(
                    <DatePicker
                      id="dtEndDate"
                      name="enddate"
                      selected={projectData.projectenddate}
                      onChange={handleEndDateChange}
                      onFocus={handleOnFocus}
                      minDate={new Date()}
                      dateFormat="dd/MM/yyyy" // Customize the date format if needed
                      className={`${validationErrors.projectenddate ? 'error' :''} ${viewOnly ? 'disabled' :''}`}
                      disabled={viewOnly}
                    />
                  )}
                  </div>
                </div>
                {validationErrors.projectenddate && (
              <label className="error error-message">
              <i className="fa-solid fa-circle-info"></i> {validationErrors.projectenddate}
          </label>
            )}
            </div>
            <hr className="line-break"></hr>
          </div>
          <p className="dialog-title col-rps-light mt-0 mb-0">Vessel Deployment</p>
          {/* New Deployment Section */}
          <div className={`vessel-deployment-section ${hasScrollbar ? 'has-scrollbar' : ''}`} ref={deploymentContentRef}>
            <div className="dialog-content vessel-list-container">
              {vessels.map((vessel, index) => (
                <div key={`divVesselEntry_` + index} className="vessel-entry">
                  <div className="mb-2">
                  <div className="d-flex align-items-center input-label-group" >
                    <label htmlFor="btnVessel" className="label-dropdown-vessel w-347 required">Vessel Title</label>
                    <div className="dropdown-container dropdown-container-vessel">
                      <button name="btnVessel" id="btnVessel" className={`btn-dropdown ${(validationErrors.vessels && validationErrors.vessels[index]) ? 'dropdown-error' :''} ${viewOnly ? 'disabled' :''} ${vesselDropdownStates[index] ? 'btn-dropdown-expanded' : ''}`} 
                          onClick={() => toggleVesselDropdown(index)}
                          onFocus={(event) => handleOnFocus(event,index)}
                          >
                          <span id="ddlSelectedOption" className="btn-dropdown-text float-left">
                          {vessel.vesselid !==''
                              ? vesselList.find(item => item.vesselid === vessel.vesselid)?.vesselname
                              : ''}
                             
                          </span>
                          <span className={`btn-dropdown-direction float-right ${vesselDropdownStates[index] ? 'col-rps' : ''}`}><i className={`fa-solid fa-chevron-down fa-set-middle`}></i></span>
                      </button>
                      <div className={`dropdown-content ${vesselDropdownStates[index] ? 'd-block' : 'd-none'}`} style={{ left: `${dropdownContentVesselLeft}px`, width: `${dropdownContentVesselWidth}px`, marginTop: `${dropdownContentVesselMarginTop}px` }}>
                    <div className="search-container">
                      <span className="search-icon">
                          <i className="fas fa-search"></i>
                      </span>
                      <input
                          type="text"
                          value={searchInputVessel}
                          onChange={handleSearchInputChangeVessel}
                          placeholder="Search..."
                          className="search-input"
                      />
                    </div>
                    <div className="dropdown-content-vessel-link dropdown-content-vessel">
                        {filteredVessels.map((ves, vesselindex) => (
                        <div key={`divFilterVessel_` + index + `_` + vesselindex} className={`dropdown-content-link ${isOptionSelected(ves.vesselid, index)}`} onClick={(e) => handleOptionClick(ves.vesselid, ves.vesselname, index)}>
                            <span>{ves.vesselname}</span>{renderSelectTag(ves.vesselid,index)}
                        </div>
                        ))}
                    </div>
                    </div>
                    </div>
                  </div>
                   {(validationErrors.vessels && validationErrors.vessels[index]) && (
                    <label className="error error-message">
                    <i className="fa-solid fa-circle-info"></i> {validationErrors.vessels[index]}
                  </label>
                      )} 
                  </div>
                  <div className="mb-2">
                  <div className="d-flex align-items-center input-label-group">
                    <label htmlFor="btnUser" className="label-dropdown-user w-347 required">Assign PAM Operator</label>
                    <div className="dropdown-container dropdown-container-user">
                    <button id="btnUser" name="btnUser" className={`btn-dropdown ${(vessel.users != null && vessel.users.length > 0) ? 'btn-dropdown-chip': ''} w-65 ${(validationErrors.users && validationErrors.users[index]) ? 'dropdown-error' :''} ${viewOnly ? 'disabled' :''} ${userDropdownStates[index] ? 'btn-dropdown-expanded' : ''}`} 
                    onClick={()=>toggleUserDropdown(index)}
                    onFocus={(event) => handleOnFocus(event,index)}
                    >
                        <span className="flex-container flex-wrap selected-chips vtext-middle">
                          {vessel.users.map((user, userindex) => (
                              <span key={`spanChipUsers_` + index + `_` + userindex} className="chip">
                              <span className="chip-text">{userList.find(item => item.userid === user.userid).preferedname}</span>
                              <span 
                              onClick={(event) => {
                                removeSelectedItem(user.userid, index); 
                                event.stopPropagation();}
                              } 
                              className="remove-button">
                                <i className={`fa-regular fa-xmark ${viewOnly ? 'd-none' :''}`}></i>
                              </span>
                            </span>
                          ))}
                        </span>
                        <span className={`btn-dropdown-direction float-right ${userDropdownStates[index] ? 'col-rps' : ''}`}><i className={`fa-solid fa-chevron-down ${(vessel.users != null && vessel.users.length > 0) ? 'fa-set-xmiddle': 'fa-set-middle'}`}></i></span>
                    </button>
                    <div className={`dropdown-content ${userDropdownStates[index] ? 'd-block' : 'd-none'}`} style={{ left: `${dropdownContentUserLeft}px`, width: `${dropdownContentUserWidth}px`, marginTop: `${dropdownContentUserMarginTop}px` }}>
                    <div className="search-container">
                      <span className="search-icon">
                          <i className="fas fa-search"></i>
                      </span>
                      <input
                          type="text"
                          value={searchInputUser}
                          onChange={handleSearchInputChangeUser}
                          placeholder="Search..."
                          className="search-input"
                      />
                    </div>
                     <div className="dropdown-content-vessel-link dropdown-content-vessel">
                        <div className={`dropdown-content-link`} onClick={() => toggleSelectAll(index)}>
                            <input
                              type="checkbox"
                              className="user-checkbox vtext-middle"
                              name="selectAllUsers"
                              checked={vessel.users.length === userList.length}
                              onChange={() => 'donothing' }
                            />
                            <label htmlFor="selectAllUsers" className="cursor-pointer">Select All</label>
                          </div>
                          {filteredUsers.map((item, userindex) => (
                            <div key={`divFilterUsers_` + index + `_` + userindex} className={`dropdown-content-link`} onClick={() => handleCheckboxChange(item.userid,index)}>
                              <input
                                  key={`inputFilterUsers_` + index + `_` + item.userid}
                                  className="user-checkbox vtext-middle"
                                  type="checkbox"
                                  value={item.userid}
                                  name={item.userid}
                                  checked={vessel.users.some(a => a.userid === item.userid)}
                                  onChange={() => 'donothing' }
                                />
                              <label htmlFor={item.userid} className="cursor-pointer">{item.preferedname}</label>
                          </div>
                        ))}
                        </div>
                    </div>
                    </div>
                    <button className={`deployment-close-button ${viewOnly ? 'disabled' :''}`} onClick={() => handleRemoveVessel(index)} >
                      <i className="fa-solid fa-xmark"></i>
                    </button>
                  </div>
                  {(validationErrors.users && validationErrors.users[index]) && (
                    <label className="error error-message">
                    <i className="fa-solid fa-circle-info"></i> {validationErrors.users[index]}
                  </label>
                      )} 
                  </div>
                </div>
              ))}
              <br></br>
              <p>Notes: These are the deployments of the vessels within the selected project.</p>
            </div>
          </div>
          {/* Action Button Section */}
          <div className="dialog-buttons float-left">
              <button id="btnNewDeployment" className="btn btn-dialog btn-secondary btn-deployment" onClick={handleAddVessel} disabled={viewOnly}>New Deployment</button>
          </div>
          <div className="dialog-buttons float-right">
              <button id="btnSaveProject" className="btn btn-dialog btn-primary btn-me"  onClick={handleSaveProject} disabled={viewOnly}>Save</button>
              <button className="btn btn-dialog btn-secondary"  onClick={handleCancel}>Cancel</button>
          </div>
          </div>
      </div>)}
      </div>
    </>
  );
}

export default ProjectDetail;
