import React, {useState, useEffect } from "react";
import "./NewUser.scss";
import UserServices from "../../services/api/UserServices";
import { useMainContext } from "../../dataproviders/MainDataProvider";
import { useAuthContext } from "../../dataproviders/AuthDataProvider";
import { useUserContext } from "../../dataproviders/UserDataProvider";

function NewUser() {
  const mainContext = useMainContext();
  const authContext = useAuthContext();
  const userContext = useUserContext();

  const [userData, setUserData] = useState({
    username: '',
    password: '',
    role: '',
    displayname:'',
  });

  const [validationErrors, setValidationErrors] = useState({
    username: '',
    password: '',
    role: '',
    displayname:'',
    passwordpolicy:'',
  });

  const handleRoleChange = (newRole) => {
    setUserData((prevUserData) => ({
      ...prevUserData,
      role: newRole,
    }));

    setValidationErrors((prevValidationErrors) => ({
      ...prevValidationErrors,
      role: '',
    }));
  };
  
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedDescription, setSelectedDescription] = useState('');

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
};

const handleOptionClick = (option, name) => {
  handleRoleChange(option);
  setSelectedOption(option);
  setSelectedDescription(name);
  setShowDropdown(false); // Hide the dropdown after an option is selected
};

const isOptionSelected = (option) => {
  return option === selectedOption ? 'selected' : '';
};

const handleHideDropdown = () => {
  setShowDropdown(false); 
};

const renderSelectTag = (option) => {
  if (option === selectedOption) {
      return (
          <span className="select-tag float-right">
              <i className="fa-solid fa-check "></i>
          </span>
      );
  }
  return (
      <span className="select-tag">
      </span>
  );
};

  const handleInputChange = (event) => {
    const fieldName = event.target.dataset.field;
    const { name, value } = event.target;

    setUserData((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
    
    //Remove password policy error message when password input has value
    if(name==='password')
    {
      setValidationErrors((prevValidationErrors) => ({
        ...prevValidationErrors,
        passwordpolicy: '',
      }));
    }

    setValidationErrors((prevValidationErrors) => ({
      ...prevValidationErrors,
      [name]: value ? '' : `${fieldName} is required.`,
    }));
  };

  const handleSaveUser = (e) => {
    const errors = {};
    if (!userData.username) {
      errors.username = 'Email is required.';
    }
    else
    {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if(!emailRegex.test(userData.username))
      {
        errors.username = 'Email format is invalid';
      }
    }

    if (!userData.displayname) {
      errors.displayname = 'Display Name is required.';
    }

    if (!userData.password) {
      errors.password = 'Password is required.';
    }
    else{
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{12,64}$/;
        if(!regex.test(userData.password))
        {
          errors.passwordpolicy = 'The password must be between 12 and 64 characters.';
        }
    }

    if (!userData.role) {
      errors.role = 'Role is required.';
    }

    setValidationErrors(errors);

    
    if (Object.keys(errors).length === 0) {
        userData.role = parseInt(userData.role);
        //Send data to server
        saveUser(userData);
       
    }
  };

    const saveUser = async (newuser) => {
        const cancelButton = document.getElementById("btnSaveUser");

        try {
            // Disable the button and add the loading class
            cancelButton.disabled = true;
            cancelButton.innerHTML = '';
            cancelButton.classList.add('loading');

            const result = await UserServices.AddUser(newuser.username, newuser.password, newuser.displayname, newuser.role);

            if (!!result) {
                if (result.data.Status) {
                    handleError('Success', "The user has now been saved.");
                    userContext.SetRefreshFlag(true);
                }
                else {
                    handleError('Error', `The user has now been failed to create due to ${result.data.ErrorMessage}`);
                }
            }
            else {
                handleError('Error', `The user has now been failed to create due to system error.`);
            }

            handleCancel();
        }
        catch (error) {
            // Display error message for network errors
            handleError('Error', 'An error occurred. Please try again later.');
        }
        finally {
            // Enable the button and remove the loading class
            cancelButton.disabled = false;
            cancelButton.innerHTML = 'Yes';
            cancelButton.classList.remove('loading');
        }
  };

    const handleCancel = () => {
        resetForm();

        mainContext.HandleAddUser(false);
    };

    const handleError = (errorType, errorMessage) => {
        authContext.HandleError(errorType, errorMessage);
    }

const resetForm = () => {
    setUserData({
      username: '',
      password: '',
      role: '',
      displayname:'',
    });

    setValidationErrors({
      username: '',
      password: '',
      role: '',
      displayname:'',
      passwordpolicy:'',
    });
    setSelectedOption('');
    setSelectedDescription('');
    setShowDropdown(false);
}

  useEffect(() => {
   
}, []);

  return( 
    <>
      <div className={`dialog-overlay ${mainContext.ShowAddUser  ? '' : 'hidden'}`}>
      <div className="dialog-container dialog-container-long">
          <button className="dialog-close-button" onClick={handleCancel} >
                <i className="fa-solid fa-xmark"></i>
          </button>
          <p className="dialog-title col-rps-light mt-0 mb-0">New User</p>
          <div className="dialog-content">
            <div className="mb-2">
              <div className="d-flex align-items-center input-label-group">
              <label htmlFor="txtEmail" className="w-30 required">Email</label>
              <input id="txtEmail" className={`${validationErrors.username ? 'error' :''} w-70`} type="text"  name="username"  data-field="Email"
              value={userData.username} 
              onChange={handleInputChange}
              onFocus={handleHideDropdown}
              required
              />
             
              </div>
              {validationErrors.username && (
                  <label className="error error-message">
                        <i className="fa-solid fa-circle-info"></i> {validationErrors.username}
                    </label>
              )}
            </div>
            <div className="mb-2">
            <div className="d-flex align-items-center input-label-group">
              <label htmlFor="txtDisplayName" className="w-30 required">Display Name</label>
              <input id="txtDisplayName" className={`${validationErrors.displayname ? 'error' :''} w-70`} type="text"  name="displayname" data-field="Display Name"
              value={userData.displayname} 
              onChange={handleInputChange}
              onFocus={handleHideDropdown}
              required
              />
            </div>
              {validationErrors.displayname && (
            <label className="error error-message">
            <i className="fa-solid fa-circle-info"></i> {validationErrors.displayname}
        </label>
          )}
            </div>
            <div className="mb-2">
              <div className="d-flex align-items-center input-label-group">
                    <label htmlFor="txtDisplayName" className="w-30 required">Role</label>
                    <button className={`btn-dropdown w-70 ${validationErrors.role ? 'dropdown-error' :''} ${showDropdown ? 'btn-dropdown-expanded' : ''}`} onClick={toggleDropdown}>
                        <span id="ddlSelectedOption" className="btn-dropdown-text float-left">{selectedDescription}</span>
                        <span className={`btn-dropdown-direction float-right ${showDropdown ? 'col-rps' : ''}`}><i className={`fa-solid fa-chevron-down`}></i></span>
                    </button>
                    <div className={`dropdown-content ${showDropdown ? 'd-block' : 'd-none'}`}>
                      <div className={`dropdown-content-link ${isOptionSelected('0')}`} onClick={() => handleOptionClick('0', 'System Administrator')}><span>System Administrator</span>{renderSelectTag('0')}</div>
                      <div className={`dropdown-content-link ${isOptionSelected('1')}`} onClick={() => handleOptionClick('1', 'PAM Operator')}><span>PAM Operator</span>{renderSelectTag('1')}</div>
                </div>
            </div>
            {validationErrors.role && (
          <label className="error error-message">
          <i className="fa-solid fa-circle-info"></i> {validationErrors.role}
      </label>
          )}
            </div>
            <div className="mb-2">
                <div className="d-flex align-items-center input-label-group">
                  <label htmlFor="txtPassword" className="w-30 required">Password</label>
                  <input id="txtPassword" className={`${validationErrors.password ? 'error' :''} w-70`}  type="text"  name="password"  data-field="Password"
                  value={userData.password} 
                  onChange={handleInputChange}
                  onFocus={handleHideDropdown}
                  required
                  />
                
                </div>
                {validationErrors.password && (
              <label className="error error-message">
              <i className="fa-solid fa-circle-info"></i> {validationErrors.password}
          </label>
            )}
                {validationErrors.passwordpolicy && (
             <label className="error error-message">
                <i className="fa-solid fa-circle-info"></i> <span>The password must be between 12 and 64 characters.</span><br />
                <span>- a lowercase letter</span><br />
                <span>- an uppercase letter</span><br />
                <span>- a digit</span><br />
                <span>- a symbol e.g. ~!@#$%^&*()</span>
            </label>
            )}
            </div>
          </div>
          <div className="dialog-buttons float-right">
              <button id="btnSaveUser" className="btn btn-dialog btn-primary btn-me"  onClick={handleSaveUser}>Save</button>
              <button className="btn btn-dialog btn-secondary"  onClick={handleCancel}>Cancel</button>
          </div>
      </div>
      </div>
    </>
  );
}

export default NewUser;
