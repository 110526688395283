import React, { useState, useEffect } from 'react';
import { Outlet, useMatch } from "react-router-dom";
import MessageBox from '../../components/shared/MessageBox';
import { useAuthContext } from '../../dataproviders/AuthDataProvider';

const ToastWrapper = () => {
    const authContext = useAuthContext();

    const handleCloseError = (id) => {
        authContext.SetErrors((prevErrors) => prevErrors.filter((error) => error.id !== id));
    }

    return (
        <>
            <Outlet />
            <div className="error-container">
                    {authContext.Errors.map((err) => (
                    <MessageBox
                        key={err.id}
                        id={err.id}
                        type={err.errorType}
                        message={err.errorMessage}
                        onClose={handleCloseError}
                    />
                ))}
            </div>
        </>
    )
}

export default ToastWrapper;