import React from "react";
import { NavLink, useMatch, useResolvedPath } from "react-router-dom";

import "./CustomNavLink.scss";

function CustomNavLink({ to, className, exact, text }) {
  let customClassName = className != null ? className : '';
  let resolved = useResolvedPath(to);
  let match = useMatch({
    path: resolved.pathname,
    end: true,
  });

  return (
    <NavLink to={to} className={`nav-link ${customClassName} `}>
      {text}
    </NavLink>
  );
}

export default CustomNavLink;
