import axios from "axios";
import { baseUrl } from "../constants";
//import { setBearerAuthorizationHeader } from "../../utils/interceptors";

const GetProjects = () => {
  return axios
    .get(`${baseUrl()}/Project/All`)
    .then((data) => {
      return data;
    })
      .catch((error) => {
        if (error.response.status === 307) {
            // Handle the specific status code 307 - Status307TemporaryRedirect
            const errResponse = error.response.data;
            let newToken = errResponse.token;

            const _auth = JSON.parse(sessionStorage.getItem("auth"));
            _auth.token = newToken;
            sessionStorage.setItem("auth", JSON.stringify(_auth));

            return axios
                .get(`${baseUrl()}/Project/All`)
                .then((data) => {
                    return data;
                })
                .catch((error) => {
                    console.error("ProjectServices: Get Projects API", error);
                });
          } else {
              console.error("ProjectServices: Get Projects API", error);
          }
    });
};

const AddProject = (project) => {
  return axios
      .post(`${baseUrl()}/Project/AddProject`, {
        projectname: project.projectname,
        projectstartdate: project.projectstartdate,
        projectenddate: project.projectenddate,
        projectdeployments: project.projectdeployments,
      })
      .then((data) => {
          return data;
      })
      .catch((error) => {
          if (error.response.status === 307) {
              // Handle the specific status code 307 - Status307TemporaryRedirect
              const errResponse = error.response.data;
              let newToken = errResponse.token;

              const _auth = JSON.parse(sessionStorage.getItem("auth"));
              _auth.token = newToken;
              sessionStorage.setItem("auth", JSON.stringify(_auth));

              return axios
                  .post(`${baseUrl()}/Project/AddProject`, {
                      projectname: project.projectname,
                      projectstartdate: project.projectstartdate,
                      projectenddate: project.projectenddate,
                      projectdeployments: project.projectdeployments,
                  })
                  .then((data) => {
                      return data;
                  })
                  .catch((error) => {
                      console.error("ProjectServices: Add Project Status API", error);
                  });
          } else {
              console.error("ProjectServices: Add Project Status API", error);
          }
      });
};

const GetProjectDetail = (id) => {
  return axios
    .get(`${baseUrl()}/Project/${id}`)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      if (error.response.status === 307) {
        // Handle the specific status code 307 - Status307TemporaryRedirect
        const errResponse = error.response.data;
        let newToken = errResponse.token;

        const _auth = JSON.parse(sessionStorage.getItem("auth"));
        _auth.token = newToken;
        sessionStorage.setItem("auth", JSON.stringify(_auth));

        return axios
            .get(`${baseUrl()}/Project/${id}`)
            .then((data) => {
                return data;
            })
            .catch((error) => {
              console.error("ProjectServices: Get Project Detail API", error);
            });
    } else {
      console.error("ProjectServices: Get Project Detail API", error);
    }
      
    });
};

const UpdateProject = (project, hasDeploymentChanged) => {
  return axios
      .post(`${baseUrl()}/Project/UpdateProject`, {
        projectname: project.projectname,
        projectstartdate: project.projectstartdate,
        projectenddate: project.projectenddate,
        projectdeployments: project.projectdeployments,
        projectid: project.projectid,
        isupdatedeployment: hasDeploymentChanged,
      })
      .then((data) => {
          return data;
      })
      .catch((error) => {
          if (error.response.status === 307) {
              // Handle the specific status code 307 - Status307TemporaryRedirect
              const errResponse = error.response.data;
              let newToken = errResponse.token;

              const _auth = JSON.parse(sessionStorage.getItem("auth"));
              _auth.token = newToken;
              sessionStorage.setItem("auth", JSON.stringify(_auth));

              return axios
                    .post(`${baseUrl()}/Project/UpdateProject`, {
                      projectname: project.projectname,
                      projectstartdate: project.projectstartdate,
                      projectenddate: project.projectenddate,
                      projectdeployments: project.projectdeployments,
                  })
                  .then((data) => {
                      return data;
                  })
                  .catch((error) => {
                      console.error("ProjectServices: Update Project Status API", error);
                  });
          } else {
              console.error("ProjectServices: Update Project Status API", error);
          }
      });
};

const DeleteProject = (id) => {
    return axios
        .delete(`${baseUrl()}/Project/${id}`)
        .then((data) => {
            return data;
        })
        .catch((error) => {
            if (error.response.status === 307) {
                // Handle the specific status code 307 - Status307TemporaryRedirect
                const errResponse = error.response.data;
                let newToken = errResponse.token;

                const _auth = JSON.parse(sessionStorage.getItem("auth"));
                _auth.token = newToken;
                sessionStorage.setItem("auth", JSON.stringify(_auth));

                return axios
                    .delete(`${baseUrl()}/Project/${id}`)
                    .then((data) => {
                        return data;
                    })
                    .catch((error) => {
                        console.error("ProjectServices: Delete Project API", error);
                    });
            } else {
                console.error("ProjectServices: Delete Project API", error);
            }

        });
};


const ProjectServices = { GetProjects, AddProject, GetProjectDetail, UpdateProject, DeleteProject };

export default ProjectServices;
