import React, { useEffect, useState } from "react";
import MessageBox from "../shared/MessageBox";
import { useAuthContext } from "../../dataproviders/AuthDataProvider";
import "./Header.scss";
import UserServices from "../../services/api/UserServices";
import { useMainContext } from "../../dataproviders/MainDataProvider";
import SessionTimeOut from '../shared/SessionTimeOut'; // Import the SessionTimeOut component

function Header() {
    const authContext = useAuthContext();
    const mainContext = useMainContext();
    const [preferedName, setPreferedName] = useState('');
    const [username, setUsername] = useState('');
    const [errors, setErrors] = useState([]);
    const [currentPassword, setCurrentPassword] = useState("default");
    const [newPassword, setNewPassword] = useState("default");
    const [confirmPassword, setConfirmPassword] = useState("default");
    const [isProfileExpanded, setProfileExpanded] = useState(false);
    const [isResetPasswordDialog, setResetPasswordDialog] = useState(false);
    const [isCurrentPasswordError, setCurrentPasswordError] = useState(false);
    const [isNewPasswordError, setNewPasswordError] = useState(false);
    const [isConfirmPasswordError, setConfirmPasswordError] = useState(false);

    useEffect(() => {
        const _auth = authContext.AuthData;

        if (_auth !== null) {
            setPreferedName(_auth.preferedName);
            setUsername(_auth.username);
        }

        return () => { };
    }, []);

    const handleProfileToggle = () => {
        setProfileExpanded(!isProfileExpanded);
    };

    const handleLogout = async() => {
        const result = await UserServices.Logout();
        authContext.Logout();
    };

    const handleResetPassword = async (e) => {

        validateCurrentPassword();
        validateNewPassword();
        validateConfirmPassword();

        let currentPassword = document.getElementById("txtCurrentPassword").value;
        let newPassword = document.getElementById("txtNewPassword").value;
        let confirmPassword = document.getElementById("txtConfirmPassword").value;

        if (!(currentPassword === '' || isCurrentPasswordError === true || newPassword === '' || isNewPasswordError === true || confirmPassword === '' || isConfirmPasswordError === true)) {
            const cancelButton = e.currentTarget;

            try {
                // Disable the button and add the loading class
                cancelButton.disabled = true;
                cancelButton.innerHTML = '';
                cancelButton.classList.add('loading');

                let username = cancelButton.getAttribute('username');

                const flag = await UserServices.ResetPassword(username, currentPassword, newPassword);

                if (!!flag) {
                    if (flag.data !== null &&
                        flag.data.errormessage === null) {
                        handleError('Success', 'Password has been successfully reset.');
                    }
                    else {
                        handleError('Error', 'Password has failed to reset. Please try again.');
                    }
                }
                else {
                    // Display error message for network errors
                    handleError('Error', 'An error occurred. Please try again later.');
                }

                handleCancelResetPasswordDialog();
            }
            catch (error) {
                // Display error message for network errors
                handleError('Error', 'An error occurred. Please try again later.');
            }
            finally {
                // Enable the button and remove the loading class
                cancelButton.disabled = false;
                cancelButton.innerHTML = 'Yes';
                cancelButton.classList.remove('loading');
            }
        }
    };

    const handleResetPasswordDialog = () => {
        setProfileExpanded(false);
        setResetPasswordDialog(true);
    };

    const handleCancelResetPasswordDialog = () => {
        setResetPasswordDialog(false);

        setCurrentPassword('default');
        setCurrentPasswordError(false);
        setNewPassword('default');
        setNewPasswordError(false);
        setConfirmPassword('default');
        setConfirmPasswordError(false);
    };

    const hasCurrentPasswordError = () => {
        if (currentPassword !== 'default' &&
            (currentPassword === '' || isCurrentPasswordError === true)) {
            return "error";
        } else {
            return "";
        }
    };

    const hasNewPasswordError = () => {
        if (newPassword !== 'default' &&
            (newPassword === '' || isNewPasswordError === true)) {
            return "error";
        } else {
            return "";
        }
    };

    const hasConfirmPasswordError = () => {
        if (confirmPassword !== 'default' &&
            (confirmPassword === '' || isConfirmPasswordError === true)) {
            return "error";
        } else {
            return "";
        }
    };

    const renderCurrentPasswordErrorMessage = () => {
        if (currentPassword === '') {
            return (
                <>
                    <label className="offset-w-30 error">
                        <i className="fa-solid fa-circle-info"></i> Current password is required.
                    </label>
                </>
            );
        }
    };

    const renderNewPasswordErrorMessage = () => {
        if (newPassword === '') {
            return (
                <>
                    <label className="offset-w-30 error">
                        <i className="fa-solid fa-circle-info"></i> New password is required.
                    </label>
                </>
            );
        }
        else if (isNewPasswordError === true) {
            return (
                <>
                    <label className="offset-w-30 error">
                        <i className="fa-solid fa-circle-info"></i> <span>The password must be between 12 and 64 characters.</span><br />
                        <span>- a lowercase letter</span><br />
                        <span>- an uppercase letter</span><br />
                        <span>- a digit</span><br />
                        <span>- a symbol e.g. ~!@#$%^&*()</span>
                    </label>
                </>
            );
        }
    };

    const renderConfirmPasswordErrorMessage = () => {
        if (confirmPassword === '') {
            return (
                <>
                    <label className="offset-w-30 error">
                        <i className="fa-solid fa-circle-info"></i> Confirm password is required.
                    </label>
                </>
            );
        }
        else if (isConfirmPasswordError === true) {
            return (
                <>
                    <label className="offset-w-30 error">
                        <i className="fa-solid fa-circle-info"></i> Password confirmation does not match.
                    </label>
                </>
            );
        }
    };

    const validateCurrentPassword = () => {
        let tempCurrentPassword = document.getElementById("txtCurrentPassword").value;
        setCurrentPassword(tempCurrentPassword);
        setCurrentPasswordError((tempCurrentPassword === ''));
    };

    const validateNewPassword = () => {
        let tempNewPassword = document.getElementById("txtNewPassword").value;
        setNewPassword(tempNewPassword);

        if (tempNewPassword !== '') {
            const passwordReset = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()]).{12,64}$/;

            if (!passwordReset.test(tempNewPassword)) {
                setNewPasswordError(true);
            } else {
                setNewPasswordError(false);
            }
        }

        validateConfirmPassword();
    };

    const validateConfirmPassword = () => {
        let tempConfirmPassword = document.getElementById("txtConfirmPassword").value;
        setConfirmPassword(tempConfirmPassword);

        if (tempConfirmPassword !== '') {
            let newPassword = document.getElementById("txtNewPassword").value;

            if (tempConfirmPassword !== newPassword) {
                setConfirmPasswordError(true);
            }
            else {
                setConfirmPasswordError(false);
            }
        }
    };

    const handleError = (errorType, errorMessage) => {
        authContext.HandleError(errorType, errorMessage);
    }

  return (
      <>
        <SessionTimeOut handleLogout={handleLogout} />
        <header className="top-header">
            <div className="logo-container">
                <div className="dashboard-logo"></div>
            </div>
            <div className="user-container">
                {isProfileExpanded ? (
                    <><i className="user-select fa-solid fa-circle-user fa-3xl cursor-pointer col-rps" onClick={handleProfileToggle}></i></>
                ) : (
                    <><i className="user-select fa-solid fa-circle-user fa-3xl cursor-pointer" onClick={handleProfileToggle}></i></>
                )}
                {isProfileExpanded && (
                    <div className="dropdown-header-menu user-profile">
                        <div className="user-profile-row user-profile-header">
                            <div className="user-profile-logo">
                                <i className="fa-solid fa-circle-user fa-xl-sm"></i>
                            </div>
                            <div className="user-profile-subcontent">
                                <div className="user-profile-subcontent-header">{preferedName}</div>
                                <div className="user-profile-subcontent-sub">{username}</div>
                            </div>
                        </div>
                        <hr className="mt-0 mb-0 ms-3 me-3" />
                        <div className="user-profile-row user-profile-content" onClick={handleResetPasswordDialog}>
                            <div className="user-profile-small-logo">
                                <i className="fa-solid fa-lock-hashtag fa-sm-lg-ss"></i>
                            </div>
                            <div className="user-profile-subcontent">Reset Password</div>
                        </div>
                        <div className="user-profile-row user-profile-content" onClick={handleLogout}>
                            <div className="user-profile-small-logo">
                                <i className="fa-solid fa-arrow-right-from-bracket fa-sm-lg-ss"></i>
                            </div>
                            <div className="user-profile-subcontent">Sign Out</div>
                        </div>
                        <hr className="mt-0 mb-0 ms-3 me-3" />
                        <div className="row user-profile-footer">
                            <div>
                                &#169; Copyright RPS Group {new Date().getFullYear().toString()}. All rights reserved.
                            </div>
                        </div>
                    </div>
                  )}
                  {isResetPasswordDialog && (
                      <div className="dialog-overlay dialog-overlay-reset-password">
                          <div className="dialog-container dialog-container-long">
                              <button className="dialog-close-button" onClick={handleCancelResetPasswordDialog}>
                                  <i className="fa-solid fa-xmark"></i>
                              </button>
                              <p className="dialog-title col-rps-light mt-0 mb-0">Reset Password</p>
                              <div className="dialog-content">
                                  <div className="mb-2">
                                      <div className="d-flex align-items-center input-label-group">
                                          <label htmlFor="txtCurrentPassword" className="w-30 required">Current Password</label>
                                          <input type="password" id="txtCurrentPassword" className={`${hasCurrentPasswordError()} w-70`} onChange={(e) => { validateCurrentPassword(); }} />
                                      </div>
                                      {renderCurrentPasswordErrorMessage()}
                                  </div>
                                  <div className="mb-2">
                                      <div className="d-flex align-items-center input-label-group">
                                          <label htmlFor="txtNewPassword" className="w-30 required">New Password</label>
                                          <input type="password" id="txtNewPassword" className={`${hasNewPasswordError()} w-70`} onChange={(e) => { validateNewPassword(); }} />
                                      </div>
                                      {renderNewPasswordErrorMessage()}
                                  </div>
                                  <div className="mb-2">
                                      <div className="d-flex align-items-center input-label-group">
                                          <label htmlFor="txtConfirmPassword" className="w-30 required">Confirm Password</label>
                                          <input type="password" id="txtConfirmPassword" className={`${hasConfirmPasswordError()} w-70`} onChange={(e) => { validateConfirmPassword(); }} />
                                      </div>
                                      {renderConfirmPasswordErrorMessage()}
                                  </div>
                              </div>
                              <div className="dialog-buttons float-right">
                                  <button className="btn btn-dialog btn-primary btn-me btn-user-reset-password" username={`${username}`} onClick={handleResetPassword}>Yes</button>
                                  <button className="btn btn-dialog btn-secondary" onClick={handleCancelResetPasswordDialog}>No</button>
                              </div>
                          </div>
                      </div>
                  )}
            </div>
          </header>
      </>
  );
}

export default Header;
