export const baseUrl = () => {
    let url = '';
    const modifyDomain = function (apiDomain) {

        // Check if the domain contains "azurewebsites.net" and "-fe"
        if (apiDomain.includes("azurewebsites.net") && apiDomain.includes("-fe")) {
            // Replace "-fe" with "-be"
            return apiDomain.replace("-fe", "-be");
        }
        else {
            // Split the domain by periods (.)
            const parts = apiDomain.split('.');

            // Check if the domain has at least two parts
            if (parts.length >= 2) {
                // Insert "-api" between the first and second parts
                parts[0] = parts[0] + '-api';

                // Join the parts back together to form the modified domain
                return parts.join('.');
            }
        }

        // If the domain doesn't have at least two parts, return the original domain
        return apiDomain;
    };

    //if only local will drop to this
    if (process.env.NODE_ENV !== 'production') {
        url = process.env.REACT_APP_BASE_URL;
    }
    else {
        url = document.location.protocol + "//" + modifyDomain(document.location.hostname);
    }
    return url;
}