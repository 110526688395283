import "./App.scss";
import "./App.bootstrap.overrides.scss";
import { RouterProvider } from "react-router-dom";
import Routers from "./router/Routers";
import { setDefaultInterceptor } from "./utils/interceptors";

function App() {
  setDefaultInterceptor();
  return (
    <div className="neptune-cloud-web">
      <RouterProvider router={Routers} />
    </div>
  );
}

export default App;
