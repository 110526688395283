import React, { useEffect, useState, useCallback } from 'react';

const SessionTimeOut = ({ handleLogout }) => {
    const timeoutDuration = parseInt(process.env.REACT_APP_SESSION_TIMEOUT, 10) || 1800; // Read session timeout from environment variable, default to 30 minutes (1800 seconds)
    const reconnectDuration = parseInt(process.env.REACT_APP_SESSION_RECONNECT, 10) || 120; // Read reconnect duration from environment variable, default to 2 minutes (120 seconds)
    const [isWarningModalOpen, setWarningModalOpen] = useState(false);
    const [reconnectTimer, setReconnectTimer] = useState(reconnectDuration);
    const [initialReconnectDuration] = useState(reconnectDuration);

    const eventTypes = [
        'keypress',
        'mousemove',
        'mousedown',
        'scroll',
        'touchmove',
        'pointermove'
    ]

    const addEventListeners = (listener) => {
        eventTypes.forEach((type) => {
            window.addEventListener(type, listener, false)
        })
    }

    const removeEventListeners = (listener) => {
        if (listener) {
            eventTypes.forEach((type) => {
                window.removeEventListener(type, listener, false)
            })
        }
    }

    const handleReconnect = useCallback(() => {
        setWarningModalOpen(false);
    }, []);

    const handleSignOut = useCallback(() => {
        // Add logic to sign out the user
        handleLogout();
    }, []);

    useEffect(() => {
        setReconnectTimer(initialReconnectDuration);

        const createTimeout1 = () => setTimeout(() => {
            setWarningModalOpen(true);
        }, timeoutDuration * 1000)

        const createTimeout2 = () => setTimeout(() => {
            // Add logic to sign out the user
            handleLogout();
        }, reconnectDuration * 1000);

        const createTimer = () => setInterval(() => {
            setReconnectTimer((prevTimer) => prevTimer - 1);
        }, 1000)

        const listener = () => {
            if (!isWarningModalOpen) {
                clearTimeout(timeout);
                clearInterval(timer);
                timeout = createTimeout1();
            }
        }

        // Initialization
        let timeout = isWarningModalOpen ? createTimeout2() : createTimeout1();
        let timer = isWarningModalOpen ? createTimer() : undefined;

        addEventListeners(listener);

        // Cleanup
        return () => {
            removeEventListeners(listener);
            clearTimeout(timeout);
            clearInterval(timer);
        }
    }, [isWarningModalOpen])

    const formattedMinutes = (Math.floor(reconnectTimer / 60)).toString().padStart(2, '0');
    const formattedSeconds = (reconnectTimer % 60).toString().padStart(2, '0');

    return (isWarningModalOpen && (
        <div className="dialog-overlay">
            <div className="dialog-container">
                <p className="dialog-title col-rps-light mt-0 mb-0">Session Expiring</p>
                <p className="dialog-content">Your session is about to expire after:</p>
                <h1 className="dialog-content-normal dialog-content-timer">{formattedMinutes} min {formattedSeconds} seconds</h1>
                <div className="float-right">
                    <button className="btn btn-primary btn-me" onClick={handleReconnect}>Reconnect</button>
                    <button className="btn btn-secondary" onClick={handleSignOut}>Sign Out</button>
                </div>
            </div>
        </div>
    ));
};

export default SessionTimeOut;
